import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { healthCheckReportDataStore } from "../../../stores/health_check_report_data_store";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";
import { DIGITAL_TRANSFORMATION_TYPES_ORDER } from "../utils";


const TargetToPeerDigitalMakeupByFunctionChart = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [headcountHistoryData, setHeadcountHistoryData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [isPercentage, setIsPercentage] = useState(true);
    const [showBenchmark, setShowBenchmark] = useState(true);

    const digitalTransformationTypesOrder = DIGITAL_TRANSFORMATION_TYPES_ORDER;
    
    const toggleStacking = () => {
        setIsPercentage(!isPercentage);
    };


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await healthCheckReportDataStore.fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const headcountHistoryData = healthCheckReportDataStore.headcountHistoryData[targetCompanyId];
            await healthCheckReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const headcountHistoryByFxData = healthCheckReportDataStore.headcountHistoryByFxData[targetCompanyId];

            if (headcountHistoryByFxData !== null) {
                setData(toJS(headcountHistoryByFxData));
                console.log('headcountHistorybyFxData', toJS(headcountHistoryByFxData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryByFxData is null');
                setDataLoading(false);
            }

            if (headcountHistoryData !== null) {
                setHeadcountHistoryData(toJS(headcountHistoryData));
                console.log('headcountHistoryData', toJS(headcountHistoryData));
            }
            else {
                console.log('headcountHistoryData is null');
            }
        };

        getData()

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawChart = (data, headcountData) => {
            const processData = () => {
        
                // Group the filtered data by name and digital_transformation_type
                let groupedData = data.reduce((acc, item) => {
                    if (!acc[item.name]) {
                        acc[item.name] = {};
                        if (item.name === targetCompanyName && showBenchmark) {
                            acc['Median'] = {};
                            acc['Top Quadrant'] = {};
                        }
                    }
                    acc[item.name][item.digital_transformation_type_name] = item.company_headcount_total;
                    // add 2 new keys to the groupedData object to plot the median data
                    if (item.name === targetCompanyName && showBenchmark) {
                        acc['Median'][item.digital_transformation_type_name] = parseFloat(item.median_pct_digital_type_benchmark * 100);
                        acc['Top Quadrant'][item.digital_transformation_type_name] = parseFloat(item.top_quadrant_hiring_retention_median_pct_digital_type_benchmark * 100);
                    }
                    return acc;
                }, {});

                // ensure Median and Top Quadrant are always at the end of the categories
                const categories = Object.keys(groupedData).sort((a, b) => {
                    if (a === 'Median') {
                        return 1;
                    }
                    else if (a === 'Top Quadrant') {
                        return 1;
                    }
                    else if (b === 'Median') {
                        return -1;
                    }
                    else if (b === 'Top Quadrant') {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });

                const transformationTypes = [...new Set(data.map(item => item.digital_transformation_type_name))];
                transformationTypes.sort((a, b) => digitalTransformationTypesOrder.indexOf(a) - digitalTransformationTypesOrder.indexOf(b));
                
                const series = transformationTypes.map(type => ({
                    name: type,
                    data: categories.map(name => groupedData[name][type] || 0)
                }));
                
                
                // const totals = categories.filter(name => (name !== 'Median' && name !== 'Top Quadrant')).map(name => 
                //     Object.values(groupedData[name]).reduce((sum, value) => sum + value, 0)
                // );

                //sort headcountData by the_date descending and select the first record
                const latestHeadcountData = headcountData.sort((a, b) => b.the_date - a.the_date)[0];
                const totals = [];
                totals[0] = parseFloat((latestHeadcountData['pct_digital'] * 100).toFixed(1));
                totals[1] = parseFloat((latestHeadcountData['median_pct_digital_benchmark'] * 100).toFixed(1));
                totals[2] = parseFloat((latestHeadcountData['top_quadrant_hiring_retention_median_pct_digital_benchmark'] * 100).toFixed(1));

                return { categories, series, totals };
              };
          
            const { categories, series, totals } = processData();
        
            // const { categories, series, totals } = processData();

            // Reusable function to create a text element
            function createTotalTextElement(chart, total, point) {
                return chart.renderer.text(
                    (`${total}%` || 'N/A').toString(),
                    point.plotX + chart.plotLeft,
                    chart.plotTop - 5
                )
                .attr({
                    align: 'center',
                    zIndex: 5
                })
                .css({
                    fontSize: '16px',
                    fontWeight: 'bold',
                    // color: '#000000'
                })
                .add();
            }

            const options = {
                chart: {
                    zoomType: 'xy',
                    type: 'column',
                    height: '85%',
                    marginTop: 60,
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                    events: {
                        load: function() {
                            const chart = this;

                            // Store references to the text elements for later removal or update
                            chart.customTextElements = [];

                            chart.series[0].data.forEach((point, i) => {
                                const textElement = createTotalTextElement(chart, totals[i], point);
                                // Store the text element reference in the customTextElements array
                                chart.customTextElements.push(textElement);
                            });
                        },
                        redraw: function() {
                            const chart = this;
                            
                            // Remove the previous text elements
                            if (chart.customTextElements) {
                                chart.customTextElements.forEach(textElement => textElement.destroy());
                                chart.customTextElements = [];
                            }

                            // Redraw totals in the correct positions
                            chart.series[0].data.forEach((point, i) => {
                                const textElement = createTotalTextElement(chart, totals[i], point);
                                // Store the new text element reference
                                chart.customTextElements.push(textElement);
                            });
                        }
                    }
                },
                title: {
                    text: 'Pct of Company in a Digital Role',
                    align: 'left', 
                    style: { 
                        fontSize: '18px',
                        marginBottom: '20px',

                    }
                },
                subtitle: {
                    text: null,
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: isPercentage ? 'Pct Makeup by Digital Function' : 'Headcount Makeup by Digital Function',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        formatter: function() {
                            if (isPercentage) {
                                return this.value + '%';
                            }
                            else {
                                // add k or m if the number is greater than 1000
                                if (this.value >= 1000) {
                                    return this.value / 1000 + 'k';
                                }
                                else if (this.value >= 1000000) {
                                    return this.value / 1000000 + 'm';
                                }
                                else {
                                    return this.value;
                                }
                            }
                            
                        },
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '16px',
                    },
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    x: -10,
                },
                tooltip: {
                    pointFormatter: function() {
                        if (isPercentage) {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                        }
                        else {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.y + '</b><br/>';
                        }
                    },
                    shared: true,
                },
                series: series,
                credits: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        stacking: isPercentage ? 'percent' : 'normal',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (isPercentage) {
                                    return this.percentage.toFixed(0) + '%';
                                }
                                else {
                                    return this.y;
                                }
                            }
                            // inside: true,
                            
                        },
                        
                    },
                }
                
            };

            Highcharts.chart('digital-makeup-by-fx-target-to-peer-chart-container', options);
        };

        if (data !== null
            && headcountHistoryData !== null
            && document.getElementById('digital-makeup-by-fx-target-to-peer-chart-container') !== null
        ) {
            const filteredData = data.filter((d) => d.current_date_flag === 1);
            drawChart(filteredData, headcountHistoryData);
        }
    }, [data, isPercentage, showBenchmark]);

        return (
            <div>
                {
                    dataLoading ?
                    (
                        <div>
                            <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                        </div>
                    )
                    :
                    (
                    <>
                        <div id="digital-makeup-by-fx-target-peer-chart">
                            <div>
                                <div id="digital-makeup-by-fx-target-to-peer-chart-container" />
                            </div>
                            <br/>
                        </div>
                    </>
                    )
                }
            </div>
        );
};

export default TargetToPeerDigitalMakeupByFunctionChart;

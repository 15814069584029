import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingWithText from '../common/loading_text';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';
// import login style file
import '../../styles/components/login.css';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
// axios.defaults.withCredentials = true;

const LoginComponent = observer(() => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [connectivityError, setConnectivityError] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);

    const notRegisteredUserMsg = "Sorry, but we cannot find this email in our records. Please register first and try again.";

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };
    
    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoggingIn(true);

        // check if its a valid email
        if (!username.includes('@')) {
            alert('Please enter a valid email address');
            return;
        }
        document.getElementById("failed-login").innerHTML = "";

        await userAccountDataStore.loginUser(username.toLowerCase(), password);
        if (userAccountDataStore.serverConnectionError) {
            document.getElementById("failed-login").innerHTML = "<p>Oops, something went wrong. The server connection is taking too long. Please try again later.</p>";
        }
        else if (userAccountDataStore.loginSuccess && userAccountDataStore.user !== null) {
            // check if email is verified
            const user = toJS(userAccountDataStore.user);
            if (user.email_verified === false) {
                navigate('/verify-email', { state: { email: username, password: password, fromLogin: true }});
                return;
            }
            
            // redirect to home page
            console.log("Login successful");
            // identify user with mixpanel and amplitude
            mixpanelActions.identify(user.uuid);
            amplitudeActions.identify(user.uuid);
            // track login event with mixpanel and amplitude
            mixpanelActions.track("Login", { status: "success", ...user });
            amplitudeActions.track("Login", { status: "success", ...user });
            navigate('/home', { state: { user: user, fromLogin: true }});
        }
        else if (userAccountDataStore.errorMsg !== '') {
            if (userAccountDataStore.errorMsg === notRegisteredUserMsg ) {
                let errorMsg = "Sorry, but we cannot find this email in our records. Please <a href='/register'>register</a> first and try again.";
                document.getElementById("failed-login").innerHTML = "<p>" + errorMsg + "</p>";
            }
            else {
                document.getElementById("failed-login").innerHTML = "<p>" + userAccountDataStore.errorMsg + "</p>";
            }
            mixpanelActions.track("Login", { status: "failure", email: username, error: toJS(userAccountDataStore.errorMsg) });
            amplitudeActions.track("Login", { status: "failure", email: username, error: toJS(userAccountDataStore.errorMsg) });
        }
        else {
            document.getElementById("failed-login").innerHTML = "<p> Oops, something went wrong. Please try again in a few mins.</p>";
            mixpanelActions.track("Login", { status: "failure", email: username, error: "N/A" });
            amplitudeActions.track("Login", { status: "failure", email: username, error: "N/A" });
        }

        setIsLoggingIn(false);
    };

    return (
        <div className="login-container">
            <br/>
            <h1 style={{textAlign: 'center'}}> Welcome to Telemetry LVR</h1>
            <div id="failed-login" style={{ color: 'red', textAlign: 'center', display: 'block'}}></div>

            <main className="login-form-container"> {/* Login Form */}
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>Login</h2>
               
                    <div className="form-group">
                        <label className="login-label" htmlFor="username">Email:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className='password-container'>
                        <label className="login-label"  htmlFor="password">Password:</label>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            title=""
                            required

                        />
                        <img
                            src={passwordShown ? `${process.env.PUBLIC_URL}/eye.png` : `${process.env.PUBLIC_URL}/closed_eye.png`}
                            alt="Toggle password visibility"
                            onClick={togglePasswordVisibility}
                            className="password-icon"
                        />
                    </div>
                    
                    <br/>
                    <button className="login-button" type="submit">Login</button>
                    <br/>
                    <br/>
                    <div id="password-error" className="error-message"></div>
                    { isLoggingIn
                        ?
                        <div>
                            <LoadingWithText texts={["Verifying credentials..", "Logging in..."]} interval={5000} />
                        </div>
                        : null
                    }
                    <div className="form-links">
                        <a href="/forgot-password">Forgot password</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="/register">New User</a>
                    </div>
                </form>
            </main>
            
        </div>
    );
});

export default LoginComponent;

import { PageLayout } from "../../components/common/page-layout"
import TalentMgmtReportKF from "../../components/report/talent_mgmt_report_kf";


export const TalentMgmtReportKFPage = () => {
    return (
        <PageLayout>
            <TalentMgmtReportKF />
        </PageLayout>
    );
};

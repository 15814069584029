import { PageLayout } from "../../components/common/page-layout"
import DigitalBenchmarkReport from "../../components/report/digital_benchmark_report";

export const DigitalBenchmarkReportPage = () => {
    return (
        <PageLayout>
            <DigitalBenchmarkReport />
        </PageLayout>
    );
};

import { PageLayout } from "../../components/common/page-layout";
import CompanyTalentRetentionComponent from "../../components/company/company_talent_retention_chart";

export const CompanyTalentRetentionPage = () => {
    return (
        <PageLayout>
            <CompanyTalentRetentionComponent />
        </PageLayout>
    );
};

import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

export const ExpandableList = ({ data }) => {
    const [openSections, setOpenSections] = useState({});

    const toggleSection = (key) => {
        setOpenSections((prevOpenSections) => ({
            ...prevOpenSections,
            [key]: !prevOpenSections[key],
        }));
    };

    return (
        <div className="list-container">
            {Object.entries(data).map(([departmentGroup, roles]) => {
                const isOpenDepartment = openSections[departmentGroup];
                const totalTitlesCount = Object.values(roles).flat().length;
                return (
                    <div key={departmentGroup} className="list-group">
                        <div className="list-title" onClick={() => toggleSection(departmentGroup)}>
                            <span className="chevron">{isOpenDepartment ? <FaChevronDown /> : <FaChevronRight />}</span> {departmentGroup} ({totalTitlesCount})
                        </div>
                        {isOpenDepartment && (
                            <div className="sublist-container">
                            {Object.entries(roles).map(([seniorityGroup, titles]) => {
                                const isOpenSeniority = openSections[departmentGroup + seniorityGroup];
                                return (
                                    <div key={seniorityGroup} className="sublist-group">
                                        <div className="sublist-title" onClick={() => toggleSection(departmentGroup + seniorityGroup)}>
                                        <span className="chevron">{isOpenSeniority ? <FaChevronDown /> : <FaChevronRight />}</span> {seniorityGroup} ({titles.length})
                                        </div>
                                        {isOpenSeniority && (
                                        <ul className="sublist">
                                            {titles.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
                                            <li key={item.title} className="list-item">
                                                <a href={item.linkedin} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                            </li>
                                            ))}
                                        </ul>
                                        )}
                                    </div>
                                );
                            })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};


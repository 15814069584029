import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import LoadingWithText from "../common/loading_text";
import { set, toJS } from "mobx";
import { observer } from "mobx-react";
import ErrorBoundary from "./common/error_boundary";
import ErrorSafeComponent from "./common/error_safe";
// import IndustryDistributionChart from "./health-check/industry_distribution_chart";
// import DigitalMakeupByFunctionChart from "./health-check/digital_makeup_by_fx_target_peer_chart";
import DigitalCapabilities from "./health-check/digital_capabilities";



const HealthCheckReport = observer(() => {
    const healthCheckReportData = JSON.parse(sessionStorage.getItem('healthCheckReportData'));
    console.log('healthCheckReportData', healthCheckReportData);
    if (healthCheckReportData === undefined || healthCheckReportData === null) {
        return (
            <div>
                <p> Oops, something went wrong, please try again later.</p>
            </div>
        );
    }


    const universeData = healthCheckReportData && healthCheckReportData.universeData ? healthCheckReportData.universeData : null;
    const targetCompanyName = healthCheckReportData && healthCheckReportData.targetCompanyName ? healthCheckReportData.targetCompanyName : null;
    const targetCompanyId = healthCheckReportData && healthCheckReportData.targetCompanyId ? healthCheckReportData.targetCompanyId : null;
    const dataset = healthCheckReportData && healthCheckReportData.datasetName ? healthCheckReportData.datasetName : null;
    const accessToken = healthCheckReportData && healthCheckReportData.accessToken ? healthCheckReportData.accessToken : null;
    const dataVersionDate = healthCheckReportData && healthCheckReportData.dataVersionDate ? healthCheckReportData.dataVersionDate : null;


    if (universeData === null || 
        targetCompanyName === null || 
        targetCompanyId === null ||
        dataset === null ||
        dataVersionDate === null
    ) {
        return (
            <div>
                <p>Oops, something went wrong, please try again later.</p>
            </div>
        );
    }

    const [dataLoading, setDataLoading] = useState(true);
    const [requestData, setRequestData] = useState({});

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
    
        // check if any of the required parameters are missing
        if (!universeData || !targetCompanyName || !targetCompanyId || !dataset) {
            console.log("Missing required parameters");
            alert("Missing required parameters, redirecting to home page...")
            // redirect to the home page
            navigate("/home");
            
            return;
        }
        else {
            if ((userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
                // get data from the server
                getAndSetData();
            }
        }
    }, []);


    const getAndSetData = async () => {
        setDataLoading(true);

        const companyList = universeData.filter(company => company.company_id === targetCompanyId).map((company) => {
            return {
                'linkedin_company_id': company.company_id,
                'name': company.company_name,
            }
        });
        

        const dataVersionDateFormat = new Date(dataVersionDate);
        const endDate = `${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;

        setRequestData({
            targetCompanyId,
            targetCompanyName,
            companyList,
            endDate,
            dataset,
        });

        setDataLoading(false);
    };
    

    return (
        <div>
        {
            dataLoading ?
            <div style={{ textAlign: "center" }} >
                <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...']} interval={3000} />
            </div>
            : 
            <div className="report-container">
                {/* <TableOfContents /> */}
                <div className="report-content">
                    <h1>Health Check Report Preview: {targetCompanyName}</h1>
                    <h4>
                        Scroll through the preview, make any adjustments as needed and then click Download Report at the bottom to get the full report.
                    </h4>
                    <br/>
                    {/* <ErrorSafeComponent>
                        <div className="chart-container">
                            <div id="industryDistribution" className="chart-container-left-health-check">
                                <IndustryDistributionChart requestData={requestData} />
                            </div>
                            <div id="industryDistribution" className="chart-container-right">
                                <DigitalMakeupByFunctionChart requestData={requestData} />
                            </div>
                        </div>
                    </ErrorSafeComponent>
                    <hr className="dotted-line"/> */}
                    <ErrorSafeComponent>
                        <DigitalCapabilities requestData={requestData} />
                    </ErrorSafeComponent>
                    <hr className="dotted-line"/>
                    {/* <ProgressModal isVisible={isModalVisible} onClose={closeModal} title={`Download Health Check Report`} progressMessage={progressMessage} />
                    <hr className="dotted-line"/>
                    <br/>
                    <br/>
                    
                    <div style={{textAlign: 'center'}} id="downloadReport">
                        <button className='button' onClick={handleDownloadReport}> Download Report </button>
                        <p> * For best performance, please use the Google Chrome browser *</p>
                    </div> */}

                </div>
            </div>
        }
        </div>
    );
});

export default HealthCheckReport;

import { PageLayout } from "../../components/common/page-layout"
import DigitalBenchmarkReportKF from "../../components/report/digital_benchmark_report_kf";

export const DigitalBenchmarkReportKFPage = () => {
    return (
        <PageLayout>
            <DigitalBenchmarkReportKF />
        </PageLayout>
    );
};

import { PageLayout } from "../../components/common/page-layout"
import TalentMgmtReport from "../../components/report/talent_mgmt_report";


export const TalentMgmtReportPage = () => {
    return (
        <PageLayout>
            <TalentMgmtReport />
        </PageLayout>
    );
};

import React, { useEffect, useMemo, useState } from 'react';
import { toJS } from "mobx";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import LoadingWithText from "../../common/loading_text";
import { digitalTranformationTypeMappingReverse } from "../utils";

const languageMapping = {
  main_flag_java: { name: "Java", order: 1 },
  main_flag_c: { name: "C", order: 2 },
  main_flag_cpp: { name: "C++", order: 3 },
  main_flag_csharp: { name: "C#", order: 4 },
  main_flag_dotnet: { name: ".Net", order: 5 },
  main_flag_asp_net: { name: "ASP.net", order: 6 },
  main_flag_cobol: { name: "Cobol", order: 7 },
  main_flag_fortran: { name: "Fortran", order: 8 },
  main_flag_pascal: { name: "Pascal", order: 9 },
  main_flag_assembly: { name: "Assembly", order: 10 },
  main_flag_visualbasic: { name: "Visual Basic", order: 11 },
  main_flag_unix_linux: { name: "Unix/Linux", order: 12 },
  main_flag_ruby: { name: "Ruby", order: 13 },
  main_flag_python: { name: "Python", order: 14 },
  main_flag_javascript: { name: "Javascript", order: 15 },
  main_flag_jquery: { name: "JQuery", order: 16 },
  main_flag_sql: { name: "SQL", order: 17 },
  main_flag_react: { name: "React", order: 18 },
  main_flag_react_native: { name: "React Native", order: 19 },
  main_flag_typescript: { name: "TypeScript", order: 20 },
  main_flag_uml: { name: "UML", order: 21 },
  main_flag_sas: { name: "SAS", order: 22 }
};

const CodingLangsTable = ({requestData, digitalType=null}) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const title = digitalType === null ? `Programming Languages By Company` : `Programming Languages By Digital Function: ${digitalTranformationTypeMappingReverse[digitalType]}`;
    const divId = digitalType === null ? 'coding-langs-table' : `coding-langs-table-${digitalType}`;
    const chartContainerId = digitalType === null ? 'coding-langs-table-container' : `coding-langs-table-container-${digitalType}`;

    const getHeatmapColor = (value) => {
        // Convert percentage string to number
        const numValue = parseFloat(value);
        
        if (isNaN(numValue)) return 'white';
      
        // Normalize the value to a range of -1 to 1
        const normalizedValue = Math.max(-1, Math.min(1, numValue / 20));

        let r, g, b;
      
        if (normalizedValue < 0) {
            // Red to White
            r = 255;
            g = b = Math.round(255 * (1 + normalizedValue));
            
        } else {
            // White to Green
            g = 255;
            r = b = Math.round(255 * (1 - normalizedValue));
            
        }

        return `rgb(${r}, ${g}, ${b})`;
    };


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            let codingLangsData = [];
            if (digitalType !== null) {
                await digitalBenchmarkReportDataStore.fetchCodingLangsByDigitalTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                codingLangsData = digitalBenchmarkReportDataStore.codingLangsByDigitalTypeData[targetCompanyId];
            }
            else {
                await digitalBenchmarkReportDataStore.fetchCodingLangsForAllCompanies(targetCompanyId, companyList, endDate, dataset);
                codingLangsData = digitalBenchmarkReportDataStore.codingLangsData[targetCompanyId];
            }

            if (codingLangsData !== null) {
                setData(toJS(codingLangsData));
                console.log('codingLangsData', toJS(codingLangsData));
            }
            else {
                console.log('codingLangsData is null');
            }
            setDataLoading(false);
        };

        getData();
    }, [targetCompanyId, companyList, endDate, dataset, digitalType]);

    const { programmingLanguages, companies, countData, percentageData } = useMemo(() => {
        if (!data) return { programmingLanguages: [], companies: [], countData: {}, percentageData: {} };

        const companies = [];
        const countData = {};
        const percentageData = {};

        const filteredData = digitalType !== null ? data.filter(company => company.digital_transformation_type === digitalType) : data;
        
        filteredData.forEach(company => {
            companies.push(company.official_name);

            Object.keys(company).forEach(key => {
                if (key.startsWith('main_flag_')) {
                    const langInfo = languageMapping[key];
                    if (langInfo) {
                        if (!countData[key]) countData[key] = {};
                        countData[key][company.official_name] = company[key];
                    }
                } else if (key.startsWith('pct_main_flag_')) {
                    const flagKey = key.replace('pct_', '');
                    const langInfo = languageMapping[flagKey];
                    if (langInfo) {
                        if (!percentageData[flagKey]) percentageData[flagKey] = {};
                        percentageData[flagKey][company.official_name] = company[key];
                    }
                }
            });
        });

        const programmingLanguages = Object.keys(languageMapping)
            .sort((a, b) => languageMapping[a].order - languageMapping[b].order);

        return {
            programmingLanguages,
            companies,
            countData,
            percentageData
        };
    }, [data]);

    // const renderTable = (tableData, headerText, valueFormatter, heatMap) => (
    //     <div style={{flex: 1, marginRight: '20px'}}>
    //         <table className="company-table" style={{width: '100%', borderCollapse: 'collapse'}}>
    //             <thead>
    //                 <tr>
    //                     <th style={{border: '1px solid black', padding: '5px'}}>{headerText}</th>
    //                     {companies.map(company => (
    //                         <th key={company} style={{border: '1px solid black', padding: '5px'}} colSpan="1">{company}</th>
    //                     ))}
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {programmingLanguages.map(lang => (
    //                     <tr key={lang}>
    //                         <td style={{border: '1px solid black', padding: '5px'}}>{languageMapping[lang].name}</td>
    //                         {companies.map(company => (
    //                             <td key={company} style={{border: '1px solid black', padding: '5px', backgroundColor: heatMap ? getHeatmapColor(tableData[lang]?.[company]) : 'white'}}>
    //                                 {valueFormatter(tableData[lang]?.[company])}
    //                             </td>
    //                         ))}
    //                     </tr>
    //                 ))}
    //             </tbody>
    //         </table>
    //     </div>
    // );

    const renderTable = (tableData, headerText, valueFormatter, isPercentageTable = false) => (
        <div style={{flex: 1, marginRight: '20px'}}>
            <table className="company-table" style={{width: '100%', tableLayout: 'auto'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: "normal", wordWrap: "break-word", wordBreak: "break-word"}}>{headerText}</th>
                        {companies.map(company => (
                            <th key={company}  colSpan="1">{company}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {programmingLanguages.map(lang => (
                        <tr key={lang}>
                            <td >{languageMapping[lang].name}</td>
                            {companies.map(company => {
                                const value = tableData[lang]?.[company];
                                const formattedValue = valueFormatter(value);
                                const cellStyle = {
                                    //border: '1px solid black',
                                    padding: '5px',
                                    ...(isPercentageTable && value !== undefined ? { backgroundColor: getHeatmapColor(formattedValue) } : {})
                                };
                                return (
                                    <td key={company} style={cellStyle}>
                                        {formattedValue}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );



    return (
        <div id={divId}>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {title} </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting coding langs data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                    <div id={chartContainerId}>
                        <div style={{marginLeft: '5%', display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'space-between'}}>
                            {renderTable(
                                countData,
                                "Num Members",
                                value => value || 0,
                                false
                            )}
                        
                            {renderTable(
                                percentageData,
                                "Pct of Digital",
                                value => value ? `${(value * 100).toFixed(0)}%` : '0%',
                                true
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CodingLangsTable;
import { PageLayout } from "../../components/common/page-layout"
import HealthCheckReport from "../../components/report/health_check_report";


export const HealthCheckReportPage = () => {
    return (
        <PageLayout>
            <HealthCheckReport />
        </PageLayout>
    );
};

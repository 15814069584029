export const DIGITAL_TRANSFORMATION_TYPES_ORDER = ['Transformation', 'Data Science & Analytics', 'Data Engineering', 'Software', 'Cloud Infrastructure', 'General Infrastructure', 'Network', 'Security', 'Support'];

export const digitalTranformationTypeMapping = {
    "Transformation": "flag_digital_innovation",
    "Data Science & Analytics": "flag_digital_data_science_and_analytics",
    "Software": "flag_digital_SW",
    "Data Engineering": "flag_digital_data",
    "Cloud Infrastructure": "flag_digital_cloud_infrastructure",
    "General Infrastructure": "flag_digital_infrastructure",
    "Network": "flag_digital_network",
    "Security": "flag_digital_security",
    "Support": "flag_digital_support",
};

// do a reverse mapping
export const digitalTranformationTypeMappingReverse = {
    "flag_digital_innovation": "Transformation",
    "flag_digital_data_science_and_analytics": "Data Science & Analytics",
    "flag_digital_SW": "Software",
    "flag_digital_data": "Data Engineering",
    "flag_digital_cloud_infrastructure": "Cloud Infrastructure",
    "flag_digital_infrastructure": "General Infrastructure",
    "flag_digital_network": "Network",
    "flag_digital_security": "Security",
    "flag_digital_support": "Support",
};

export const talentTypeMapping = {
    "Change Management": "flag_hr_change_mgmt",
    "Culture": "flag_hr_culture",
    "DEI": "dei_core",
    "Coaching": "flag_coach",
    "Compensation & Benefits": "flag_hr_comp_benefits",
    "Talent Acquisition": "flag_hr_talent_acquis",
    "Learning & Development": "flag_hr_learning_and_dev",
    "Talent Management": "flag_hr_talent_mgmt",
    "EHS": "flag_hr_ehs",
    "Payroll": "flag_hr_payroll",
    "HR Generalist": "flag_hr_general",
};

export const talentTypeMappingReverse = {
    "flag_hr_change_mgmt": "Change Management",
    "flag_hr_culture": "Culture",
    "dei_core": "DEI",
    "flag_coach": "Coaching",
    "flag_hr_comp_benefits": "Compensation & Benefits",
    "flag_hr_talent_acquis": "Talent Acquisition",
    "flag_hr_learning_and_dev": "Learning & Development",
    "flag_hr_talent_mgmt": "Talent Management",
    "flag_hr_ehs": "EHS",
    "flag_hr_payroll": "Payroll",
    "flag_hr_general": "HR Generalist",
};

export const ProgressModal = ({ isVisible, onClose, title, progressMessage }) => {
    if (!isVisible) return null;

    return (
        <div className="modal">
        <div className="modal-content">
            {/* display a X to close the modal */}
            <span className="close" onClick={onClose}>&times;</span>
            <h2>{title}</h2>
            <h3> Please stay on the current tab until the download completes. This operation may take several minutes. Thank you!</h3>
            <p>{progressMessage}</p>
            {/* You can also add a progress bar here */}
        </div>
        </div>
    );
}
  
import { toJS } from "mobx";
import { useEffect, useMemo, useState } from "react";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";


const AvgCohortRetentionByFunctionChart = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const NUM_HIRED_THRESHOLD = 5;

    const uniqueByKeys = (data, key1, key2, key3) => {
        const uniqueData = data.map(item => ({
            [key1]: item[key1],
            [key2]: item[key2],
            [key3]: item[key3],
        }))
        .reduce((acc, current) => {
            if (!acc.some(item => item[key1] === current[key1] && item[key2] === current[key2] && item[key3] === current[key3])) {
            acc.push(current);
            }
            return acc;
        }, []);
        
        return uniqueData;
    };


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await digitalBenchmarkReportDataStore.fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const retentionHistoryData = digitalBenchmarkReportDataStore.retentionHistoryData[targetCompanyId];

            if (retentionHistoryData !== null) {
                setData(toJS(retentionHistoryData));
                console.log('retentionHistoryData', toJS(retentionHistoryData));
                setDataLoading(false);
            }
            else {
                console.log('retentionHistoryData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);


    const getProcessedCompanyRetentionDataByTeams = (data) => {
        // Create a map where each key is a hired_year and the value is an object
        // with keys for each tenure month and values for retention.
        // Also store the num_people where company_exp_tenure_months_normalized is 1.
        const groupedByTeams = {};

        data.forEach((item) => {
            const { digital_transformation_type, company_exp_tenure_months_normalized, pct_retained_avg, num_people } = item;
            
            if (!groupedByTeams[digital_transformation_type]) {
                groupedByTeams[digital_transformation_type] = {
                    retention: {}, // Initialize retention data
                  
                };
            }

            // Only store num_people for company_exp_tenure_months_normalized = 1
            if (company_exp_tenure_months_normalized === 1) {
                groupedByTeams[digital_transformation_type].num_people = num_people;
            }

            // Store the retention data for each tenure month
            groupedByTeams[digital_transformation_type].retention[company_exp_tenure_months_normalized] = `${(pct_retained_avg * 100).toFixed(0)}%`;
            
        });

        // Now transform this map into an array of objects for rendering
        const processedData = Object.entries(groupedByTeams).map(([dept, data]) => {
            // Each entry in the array is an object with a key for the year, num_people, and each tenure month
            return {
                dept,
                num_people: Math.round(parseFloat(data.num_people)) || 'N/A', // Use 'N/A' if num_people is undefined
                retention: data.retention,
            };
        });

        // remove dept = 'Other' from the processedData and append it to the end
        const otherDeptIndex = processedData.findIndex((item) => item.digital_transformation_type === 'Other');
        const otherDeptData = processedData.splice(otherDeptIndex, 1);
        processedData.push(otherDeptData[0]);

        return processedData;
    };

    const getHeatmapColor = (percentage) => {
        if (percentage === undefined)
            return 'white';
        // You would define your own logic here to return a color based on the value
        const value = parseFloat(percentage.replace('%', ''));
        // if (value >= 80) return '#2CC0DD';
        // if (value >= 60) return '#25A2BB';
        // if (value >= 40) return '#1F8599';
        // if (value >= 20) return '#186777';
        if (value >= 80) return '#3cdfff';
        if (value >= 60) return '#63e5ff';
        if (value >= 40) return '#8aecff';
        if (value >= 20) return '#b1f2ff';
        return '#d8f9ff'; // Less than 20%
    };

    useEffect(() => {
        const drawCohortRetentionByTeamsChart = (data) => {
            const filteredData = data.filter(item => item.company_id === targetCompanyId);
            const uniqueData = uniqueByKeys(filteredData, 'digital_transformation_type_name', 'company_exp_tenure_years_normalized', 'pct_retained_avg');
            // Extract unique company names
            const categories = [...new Set(uniqueData.map(item => item.digital_transformation_type_name))];

            // Prepare the series data
            const seriesData = [];
            categories.forEach(category => {
                const dataForCompany = filteredData.filter(item => item.digital_transformation_type_name === category && item.company_exp_tenure_years_normalized === '0');
                const avgNumPeople = dataForCompany.reduce((acc, current) => acc + current.num_people, 0) / dataForCompany.length;
                
                if (avgNumPeople < NUM_HIRED_THRESHOLD) {
                    return;
                }

                const dataForCategory = uniqueData.filter(d => d.digital_transformation_type_name === category);
                const seriesItems = [];
                dataForCategory.forEach(item => {
                    if (item.company_exp_tenure_years_normalized !== 'current') {
                        seriesItems.push([parseFloat(item.company_exp_tenure_years_normalized), parseFloat((item.pct_retained_avg * 100).toFixed(0))]);
                    }
                });
                seriesData.push({
                    name: category,
                    data: seriesItems,
                    type: 'spline',
                });
            });

            console.log('seriesData', seriesData);

            Highcharts.chart('cohort-retention-teams-chart-container', {
                chart: {
                    zoomType: 'xy',
                    type: 'spline',
                    height: '25%',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    title : {
                        text: 'Years Retained',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    tickInterval: 1,
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text:  'Avg Cohort Retention Rate by Digital Function',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        format: '{value}%',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    max: 100,
                },
                legend: {
                    enabled: true,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    floating: false,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                
                series: seriesData,
    
                credits: {
                    enabled: false
                },
                tooltip: { 
                    formatter: function() {
                        var series = this.series.chart.series;
                        var result = '';
                        result += '<b><span style="color:' +
                                    this.point.color + '">\u25CF</span> ' +
                                    this.series.name + '</b>: <b>' + this.point.y +
                                    '%</b><br/>';
                     
                        series.forEach(function(s) {
                            if (s !== this.series) {
                                result += '<span style="color:' +
                                        s.points[this.point.index].color +
                                        '">\u25CF</span><span style="color: #636363"> ' +
                                        s.name + ': ' + s.points[this.point.index].y +
                                        '%</span><br/>';
                            }
                        }, this);
                    
                        return result;
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 2,
                    },
                },
            });
        };


        if (data !== null
            && document.getElementById('cohort-retention-teams-chart-container') !== null
        ) { 
            drawCohortRetentionByTeamsChart(data);
        }

    }, [data]);

    const tableContent = useMemo(() => {
        if (!data || data.length === 0) return null;
        const filteredData = data.filter(item => item.company_id === targetCompanyId);
        const uniqueData = uniqueByKeys(filteredData, 'digital_transformation_type_name', 'company_exp_tenure_years_normalized', 'pct_retained_avg');

        // Get unique company names and experience years
        const digitalTypes = [...new Set(uniqueData.map(item => item.digital_transformation_type_name))];
        const expYears = [...new Set(uniqueData.map(item => item.company_exp_tenure_years_normalized))].sort((a, b) => {
            if (a === 'current') return 1;
            if (b === 'current') return -1;
            return parseFloat(a) - parseFloat(b);
        });
        // remove 'current' from expYears
        expYears.pop();

        // Create a map for easy data lookup
        const dataMap = new Map(uniqueData.map(item => [
            `${item.digital_transformation_type_name}-${item.company_exp_tenure_years_normalized}`,
            item.pct_retained_avg*100
        ]));

        // Create a map for avg num_people per company
        const avgNumPeopleMap = {};
        digitalTypes.forEach(type => {
            const dataForCompany = filteredData.filter(item => item.digital_transformation_type_name === type && item.company_exp_tenure_years_normalized === '0');
            // calculate the average number of people
            const avgNumPeople = dataForCompany.reduce((acc, current) => acc + current.num_people, 0) / dataForCompany.length;
            avgNumPeopleMap[type] = Math.round(avgNumPeople);
        });

        return (
            <table className="company-table">
                <thead>
                <tr>
                    <th>Digital Function</th>
                    <th>Avg Num Hired Per Year</th>
                    {expYears.map(year => (
                    <th key={year}>{year}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {digitalTypes.map(type => (
                    <tr key={type}>
                    <td>{type}</td>
                    <td>{putCommasInNumber(avgNumPeopleMap[type])}</td>
                    {expYears.map(year => {
                        if (avgNumPeopleMap[type] < NUM_HIRED_THRESHOLD) {
                            return <td key={`${type}-${year}`}>-</td>;
                        }
                        const value = dataMap.get(`${type}-${year}`);

                        return (
                            <td style={{backgroundColor: getHeatmapColor(`${dataMap.get(`${type}-${year}`)?.toFixed(0)}%`)}} key={`${type}-${year}`}>
                                {value ? `${value.toFixed(0)}%` : '-'}
                            </td>
                        );
                    })}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }, [data]);


    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2> {targetCompanyName}: Avg Cohort Retention by Digital Function </h2>
            </div>
            {
                dataLoading ?
                (
                    <div>
                        <LoadingWithText texts={['Requesting retention data...', 'Finished']} interval={5000} />
                    </div>
                )
                :
                (
                <>
                    <div id="avg-cohort-retention-by-function-chart">
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            <div id="cohort-retention-teams-chart-container" />
                        </div>
                        <br/>
                        <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                            {tableContent}
                        </div>
                    </div>
                    <p> *Note: Retention data not shown when the Avg Num Hired per Year is less than 5.* </p>
                </>
                )
            }
        </div>
    );
};

export default AvgCohortRetentionByFunctionChart;

import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getApiServrUrl, getLastDateOfMonth, getNumberOfDaysBetweenDates, numberFormatter, numberToCurrencyUSDFormatter, putCommasInNumber, toTitle } from "../../utils/utils";

import { universeDisplayNames, universeDisplayNamesForColumns } from "../../utils/constants";
import Highcharts from 'highcharts';
import AnnotationsModule from 'highcharts/modules/annotations';
import { ClipLoader } from "react-spinners";
import { DownloadButton } from "../common/download_button";
import { dataVersionDateStore } from "../../stores/data_version_date_store";
import { universeSummaryDataStore } from "../../stores/universe_summary_data_store";
import { userAccountDataStore } from "../../stores/user_account_data_store";
import { toJS } from "mobx";
import { mixpanelActions } from "../../utils/mixpanel_util";
import { amplitudeActions } from "../../utils/amplitude_util";
import axios from "axios";
import { apiEventsStore } from "../../stores/api_events_store";
import LoadingWithText from "../common/loading_text";

AnnotationsModule(Highcharts);

const UniverseChartComponent = ()   => {
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isDeletable, setIsDeletable] = useState(false);
    const [clientIds, setClientIds] = useState([]);
    const [universeData, setUniverseData] = useState([]);
    const [universeHistData, setUniverseHistData] = useState([]);
    const [plotData, setPlotData] = useState([]);
    const [currPoint, setCurrPoint] = useState(null);
    const [datasetName, setDatasetName] = useState(null);
    const [seriesDataCompanyMapper, setSeriesDataCompanyMapper] = useState({});

    const [showAverage, setShowAverage] = useState(false);
    const [showMedian, setShowMedian] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
    const [collectionUpdating, setCollectionUpdating] = useState(false);
    const [collectionUpdateComplete, setCollectionUpdateComplete] = useState(false);
    const [collectionArchiving, setCollectionArchiving] = useState(false);
    const [collectionArchiveComplete, setCollectionArchiveComplete] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);

    const [isUnAuthorized, setIsUnAuthorized] = useState(false);
    const [noAccess, setNoAccess] = useState(false);

    const [companySummaryData, setCompanySummaryData] = useState(null);
    const [companyInsightsUrl, setCompanyInsightsUrl] = useState('');
    const [companyTeamsUrl, setCompanyTeamsUrl] = useState('');
    const [companyTalentUrl, setCompanyTalentUrl] = useState('');
    const [companyTalentRetentionUrl, setCompanyTalentRetentionUrl] = useState('');
    const [companyTalentRetentionPlusUrl, setCompanyTalentRetentionPlusUrl] = useState('');
    const [companyTenureUrl, setCompanyTenureUrl] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [selectedChartView, setSelectedChartView] = useState(
        { 
            id: 1, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employee Retention vs Net Headcount Growth' 
        }
    );
    const [universeName, setUniverseName] = useState('');
    const [isDigitalBMReportModalVisible, setIsDigitalBMReportModalVisible] = useState(false);
    const [isTalentMgmtReportModalVisible, setIsTalentMgmtReportModalVisible] = useState(false);
    const [competitorCompanies, setCompetitorCompanies] = useState({});
    const [orderedCompetitors, setOrderedCompetitors] = useState([]);
    const [renameTarget, setRenameTarget] = useState(false);
    const [targetName, setTargetName] = useState(null);
    
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const universeId = searchParams && searchParams.get('universe_id') || location.state && location.state.universeId;
    const accessToken = searchParams && searchParams.get('access_token') || location.state && location.state.accessToken;
    const forceRefresh = searchParams && searchParams.get('force_refresh') || location.state && location.state.forceRefresh;

    const KF_DIGITAL_REPORT_ACCESS_LIST = ['amelia.haynes@kornferry.com', 'andrew.hall@kornferry.com', 'emily.gianunzio@kornferry.com', 'stephen.lams@kornferry.com', 'tessa.misiaszek@kornferry.com'];

    const displayCreateReport = () => {
        return true;
    };

    const isTelemetryLogin = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com") {
                return true;
            }
        }
        return false;
    };

    const isKornFerryLogin = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com") {
                return true;
            }
        }
        return false;
    };

    const isEndavaLogin = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "endava.com") {
                return true;
            }
        }
        return false;
    };

    const isRestrictedLogin = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "silverlake.com" || emailDomain === "vistaequitypartners.com" || emailDomain === "american-securities.com" || emailDomain === "bcg.com") {
                return true;
            }
        }
        return false;
    };

    const displayTalentRetention = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "kornferry.com" 
                || emailDomain === "endava.com" 
                || emailDomain === "bcg.com"
                || emailDomain === "american-securities.com"
                || emailDomain === "telemetryllc.com" 
                || emailDomain === "telemetry-llc.com" 
            ) {
                return true;
            }
            else if (emailDomain === "gmail.com") {
                if (userAccountDataStore.user.email === 'zoltan.peresztegi@gmail.com') {
                    return true;
                }
            }
        }
        return false;
    };

    const displayTalentRetentionPlus = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            let emailDomain = userAccountDataStore.user.email.split('@')[1];
            if (emailDomain === "telemetryllc.com" || emailDomain === "telemetry-llc.com") {
                return true;
            }
        }
        return false;
    };

    const chartViewOptions = [
        { 
            id: 1, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employee Retention vs Net Headcount Growth' 
        },
        { 
            id: 2, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Employee Addition']
            ],
            label: 'Employee Retention vs Employee Addition' 
        },
        { 
            id: 3, 
            value: [
                universeDisplayNames['Employee Retention'],
                universeDisplayNames['Growth Efficiency']
            ],
            label: 'Employee Retention vs Growth Efficiency'
        },
        { 
            id: 4, 
            value: [
                universeDisplayNames['Employees'],
                universeDisplayNames['Net Headcount Growth']
            ],
            label: 'Employees vs Net Headcount Growth'
        },
    ];

    const getDataVersionDate = async () => {
        await dataVersionDateStore.fetchDataVersionDate(accessToken);
    };
    
    const getUniverseData = async () => {
        await universeSummaryDataStore.fetchUniverseSummaryData(universeId, accessToken, forceRefresh);
        const jsonData = toJS(universeSummaryDataStore.data[universeId]);
        // loop thru jsonData and select the highest the_date value for each unique company_id
        // let currData = [];
        // let uniqueCompanyIds = [...new Set(jsonData.map(item => item.company_id))];
        // for (let i=0; i<uniqueCompanyIds.length; i++) {
        //     let companyData = jsonData.filter(item => item.company_id === uniqueCompanyIds[i]);
        //     let maxDate = Math.max(...companyData.map(item => item.the_date));
        //     let currItem = companyData.find(item => item.the_date === maxDate);
        //     currData.push(currItem);
        // };
        // setUniverseHistData(jsonData);
        setUniverseData(jsonData);
        setUniverseName(jsonData[0].universe_display_name);
        setDatasetName(jsonData[0].dataset_id); 
        let clients = jsonData[0].client_ids.split(',');
        // remove white spaces
        clients = clients.map((item) => item.trim());

        // check if user has access to this universe
        if (userAccountDataStore.user !== null && !clients.includes(userAccountDataStore.user.email)) {
            setNoAccess(true);
            return;
        }

        // remove admin@telemetryllc.com and user email
        clients = clients.filter((item) => item !== 'admin@telemetryllc.com' && userAccountDataStore.user !== null && item !== userAccountDataStore.user.email);
        console.log(clients);
        setClientIds(clients);

        setIsEditable(userAccountDataStore.user !== null && userAccountDataStore.user.email === jsonData[0].created_by);
        // can only delete/archive collections that are not shared with anyone
        setIsDeletable(userAccountDataStore.user !== null && userAccountDataStore.user.email === jsonData[0].created_by && clients.length === 0);

        let rev = 0;
        let employees = 0;
        for (let i=0 ; i<jsonData.length ; i++) {
            rev += jsonData[i].estimated_revenue;
            employees += jsonData[i].company_employees_count_all;
        };
        setTotalRevenue(rev);
        setTotalEmployees(employees);
        setTotalCompanies(jsonData.length);
        let t = numberToCurrencyUSDFormatter(rev);
        let f = numberFormatter(employees);

        let companyOptions = ['All'];
        companyOptions.push(... new Set(jsonData.map((item) => item.company_name)));
        setCompanyOptions(companyOptions);
        setSelectedCompany('All');

        setIsPageLoading(false);
    };

    const handleChartViewChange = (option) => {
        setSelectedChartView(option);
        setCompanySummaryData(null);
    };

    function getUniverseChartOptions() {
        const seriesData = [];
        let dataMapper = {};

        for (let i=0; i<universeData.length; i++) {
            let x = 0;
            let y = 0;

            if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                x = parseFloat((universeData[i][selectedChartView.value[0]] * 100).toFixed(1));  
                y = parseFloat((universeData[i][selectedChartView.value[1]] * 100).toFixed(1));
            }
            else {
                x = universeData[i][selectedChartView.value[0]]
                y = parseFloat((universeData[i][selectedChartView.value[1]] * 100).toFixed(1));
            }
            seriesData.push(
                [x, y]
            );

            let xy = x.toString() + ',' + y.toString();
            
            dataMapper[xy] = {
                index: i,
                companyName: universeData[i].company_name,
            };
        };
        setSeriesDataCompanyMapper(dataMapper);
        const annotations = [];
        seriesData.map((item) => {
            annotations.push({
                labels: [{
                    point: {
                        x: item[0],
                        y: item[1],
                        xAxis: 0,
                        yAxis: 0, 
                    },
                    text: dataMapper[item[0].toString() + ',' + item[1].toString()]['companyName'],
                }],
                labelOptions: {
                    shape: 'connector',
                    align: 'right',
                    justify: false,
                    crop: true,
                    style: {
                        fontSize: '16px',
                    },
                    allowOverlap: true,
                },
                draggable: 'xy',
            });
        });


        let options = {
            annotations: annotations,
            chart: {
                type: 'scatter',
                height: 720,
                zoomType: 'xy',
            },
            title: {
                text: universeName,
            },
            subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[0]],
                },
                labels: {
                    formatter: function() {
                        if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                            return this.value + '%';
                        }
                        else {
                            // format number to have K for thousands and M for millions
                            return numberFormatter(this.value);
                            // return this.value;
                        }
                    }
				},
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[1]],
                },
                labels: {
					format: '{value}%'
				},
                gridLineWidth: 1,
            },
            series: [
                {
                    name: '',
                    data: seriesData,
                    marker: {
                        symbol: 'circle',
                        states: {
                            select: {
                                fillColor: 'coral',
                                radius: 10,
                                lineWidth: 0,
                            },
                        },
                    },
                },
            ],
            tooltip: {
                formatter: function() {
                    // check if dataMapper is present
                    if (Object.keys(dataMapper).length === 0) {
                        return;
                    }
                    else {
                        let key = this.x + ',' + this.y;
                        // check if key is present in dataMapper
                        if (!dataMapper.hasOwnProperty(key)) {
                            return;
                        }
                        let companyName = dataMapper[this.x + ',' + this.y]['companyName'];
                        let xLabel = universeDisplayNamesForColumns[selectedChartView.value[0]];
                        let yLabel = universeDisplayNamesForColumns[selectedChartView.value[1]];

                        let label = '';
                        if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                            label = `<b>${companyName}</b>` + '<br/>' + xLabel + ': ' + this.x + '%' + '<br/>' +
                                        yLabel + ': ' + this.y + '%' + '<br/>';
                        }
                        else {
                            label = `<b>${companyName}</b>` + '<br/>' + xLabel + ': ' + putCommasInNumber(this.x) + '<br/>' +
                                        yLabel + ': ' + this.y + '%' + '<br/>';
                        }

                        return label;
                    }
                },               
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 10
                    },
                    point: {
                        events: {
                            click: function (event) {
                                // check if dataMapper is present
                                if (Object.keys(dataMapper).length === 0) {
                                    return;
                                }
                                else {
                                    let key = this.x + ',' + this.y;
                                    // check if key is present in dataMapper
                                    if (!dataMapper.hasOwnProperty(key)) {
                                        return;
                                    }
                                    let xyKey = this.x + ',' + this.y;
                                    let companyName = dataMapper[this.x + ',' + this.y]['companyName'];
                                    setCompanySummary(companyName);
                                    setSelectedCompany(companyName);
                                    // find key for selected input in seriesDataCompanyMapper
                                    setSelectedPointIndex(dataMapper[xyKey]['index']);

                                    if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                                        mixpanelActions.track("Click", {
                                            location: "Universe Summary",
                                            type: "Data click",
                                            universeId: universeId,
                                            universeName: universeName,
                                            companyName: companyName,
                                            user: toJS(userAccountDataStore.user),
                                        });
                                        amplitudeActions.track("Click", {
                                            location: "Universe Summary",
                                            type: "Data click",
                                            universeId: universeId,
                                            universeName: universeName,
                                            companyName: companyName,
                                            user: toJS(userAccountDataStore.user),
                                        });
                                    }
                                }
                            }
                        }
                    },
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            }
        };

        if (showAverage) {
            const averages = calculateAverage(seriesData);
            options.series.push(
                {
                    type: 'spline',
                    name: `Average ${universeDisplayNamesForColumns[selectedChartView.value[0]]}`,
                    data: [[averages[0], Math.min(...seriesData.map(d => d[1]))], [averages[0], Math.max(...seriesData.map(d => d[1]))]],
                    color: 'black',
                    marker: {
                        enabled: false,
                    },
                },
                {
                    type: 'spline',
                    name: `Average ${universeDisplayNamesForColumns[selectedChartView.value[1]]}`,
                    data: [[Math.min(...seriesData.map(d => d[0])), averages[1]], [Math.max(...seriesData.map(d => d[0])), averages[1]]],
                    color: 'black',
                    marker: {
                        enabled: false,
                    },
                }
            );

        };
        if (showMedian) {
            const medians = calculateMedian(seriesData);
            // Calculating the min and max values from your data
            const xValues = seriesData.map(point => point[0]);
            const yValues = seriesData.map(point => point[1]);

            const xMin = Math.min(...xValues);
            const xMax = Math.max(...xValues);
            const yMin = Math.min(...yValues);
            const yMax = Math.max(...yValues);

            // Adding the median lines
            options.series.push(
                {
                    type: 'spline',
                    name: `Median Employee Retention`,
                    data: [[xMin, medians[1]], [xMax, medians[1]]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: false,
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: `Median Net Headcount Growth`,
                    data: [[medians[0], yMin], [medians[0], yMax]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: false,
                    dashStyle: 'Dash',
                }
            );
        };

        return options;
    };

    function calculateAverage(seriesData) {
        let xSum = seriesData.reduce((sum, value) => sum + value[0], 0);
        const xAvg = xSum / seriesData.length;

        let ySum = seriesData.reduce((sum, value) => sum + value[1], 0);
        const yAvg = ySum / seriesData.length;

        return [xAvg, yAvg];
    };

    function calculateMedian(seriesData) {
        const xSorted = seriesData.map(item => item[0]).sort((a, b) => a - b);
        const mid = Math.floor(xSorted.length / 2);
        let xMedian = xSorted[mid];
        if (xSorted.length % 2 === 0) {
            xMedian = (xSorted[mid - 1] + xSorted[mid]) / 2;
        }
        
        const ySorted = seriesData.map(item => item[1]).sort((a, b) => a - b);
        const yMid = Math.floor(ySorted.length / 2);
        let yMedian = ySorted[yMid];
        if (ySorted.length % 2 === 0) {
            yMedian = (ySorted[yMid - 1] + ySorted[yMid]) / 2;
        }

        return [xMedian, yMedian];
    };

    function setCompanySummary(companyName) {
        let companyData = universeData.find(item => item.company_name === companyName);
        // add company_linkedin_url to companyData
        let companyLinkedInUrl = `https://www.linkedin.com/company/${companyData.company_shorthand_name}/`;
        companyData['company_linkedin_url'] = companyLinkedInUrl;
        setCompanySummaryData(companyData);
        setTargetName(companyName);

        let companyInsightsUrl = `/company/growth?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        let companyTeamsUrl = `/company/team/growth?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        let companyTalentUrl = `/company/talent?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        let companyTenuresUrl = `/company/tenure?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        let companyTalentRetentionUrl = `/company/talent/retention?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        let companyTalentRetentionPlusUrl = `/company/talent/retention/plus?universe_id=${universeId}&company_id=${companyData.company_id}&dataset=${datasetName}`;
        if (accessToken !== null && accessToken !== undefined) {
            companyInsightsUrl += `&access_token=${accessToken}`;
            companyTeamsUrl += `&access_token=${accessToken}`;
            companyTalentUrl += `&access_token=${accessToken}`;
            companyTenuresUrl += `&access_token=${accessToken}`;
            companyTalentRetentionUrl += `&access_token=${accessToken}`;
            companyTalentRetentionPlusUrl += `&access_token=${accessToken}`;
        }
        setCompanyInsightsUrl(companyInsightsUrl);
        setCompanyTeamsUrl(companyTeamsUrl);
        setCompanyTalentUrl(companyTalentUrl);
        setCompanyTenureUrl(companyTenuresUrl);
        setCompanyTalentRetentionUrl(companyTalentRetentionUrl);
        setCompanyTalentRetentionPlusUrl(companyTalentRetentionPlusUrl);
    };

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        if (event.target.value !== 'All') {
            setCompanySummary(event.target.value);
            // find key for selected input in seriesDataCompanyMapper
            let xyKey = Object.keys(seriesDataCompanyMapper).find(key => seriesDataCompanyMapper[key]['companyName'] === event.target.value);
            setSelectedPointIndex(seriesDataCompanyMapper[xyKey]['index']);
        }
        else {
            setCompanySummaryData(null);
        }

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Highlight Company",
                universeId: universeId,
                universeName: universeName,
                companyName: event.target.value,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Highlight Company",
                universeId: universeId,
                universeName: universeName,
                companyName: event.target.value,
                user: toJS(userAccountDataStore.user),
            });
        }
        
    };
    
    const handleCloneClicked = (event) => {
        event.preventDefault();

        // open modal to clone universe
        setIsModalVisible(true);
    };

    const handleArchiveClicked = (event) => {
        event.preventDefault();

        // open modal to archive universe
        setIsArchiveModalVisible(true);
    }

    const handleConfirmClicked = async () => {
        // Send the cloning request to the server
        // Navigate back to the home page
        setCollectionUpdating(true);

        // disable the save changes button
        document.getElementById('confirm-changes-button').disabled = true;        
        
        let path = `/universe/summary`;

        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_CLONE_UNIVERSE_ENDPOINT;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            decompress: true,
            // withCredentials: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        let data = {
            'universe_id': universeId,
            'universe_name': universeName + ' Copy',
            'client_email': toJS(userAccountDataStore.user.email),
        };

        try {
            const response = await axios.post(apiUrl, data, config); 
            const clonedUniverseId = response.data.data['universe_id'];
            path += `?universe_id=${clonedUniverseId}`;
            
            setCollectionUpdating(false);
            setCollectionUpdateComplete(true);
            
            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                // track update universe event
                mixpanelActions.track("Clone Collection", {
                    universeId: universeId,
                    universeName: universeName,
                    user: toJS(userAccountDataStore.user),
                });
                amplitudeActions.track("Clone Collection", {
                    universeId: universeId,
                    universeName: universeName,
                    user: toJS(userAccountDataStore.user),
                });

                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId: null,
                    eventType: 'universe_clone',
                    eventData: {
                        universeId: universeId,
                        universeName: universeName,
                    },
                });
            }

            // defer the navigation to the home page
            setTimeout(() => {
                navigate(path, {state: { clonedUniverseId, accessToken, forceRefresh: true }});
                setIsModalVisible(false);
                // force reload the page
                window.location.reload();
            }, 2000); 
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleArchiveConfirmClicked = async () => {
        // Send the archive request to the server
        // Navigate back to the home page
        setCollectionArchiving(true);

        // disable the save changes button
        document.getElementById('confirm-changes-button').disabled = true;        
        
        let path = `/home`;

        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_ARCHIVE_UNIVERSE_ENDPOINT;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            decompress: true,
            // withCredentials: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        let data = {
            'universe_id': universeId,
        };

        try {
            const response = await axios.post(apiUrl, data, config); 
            
            setCollectionArchiving(false);
            setCollectionArchiveComplete(true);
            
            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                // track update universe event
                mixpanelActions.track("Archive Collection", {
                    universeId: universeId,
                    universeName: universeName,
                    user: toJS(userAccountDataStore.user),
                });
                amplitudeActions.track("Archive Collection", {
                    universeId: universeId,
                    universeName: universeName,
                    user: toJS(userAccountDataStore.user),
                });

                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId: null,
                    eventType: 'universe_archive',
                    eventData: {
                        universeId: universeId,
                        universeName: universeName,
                    },
                });
            }

            // defer the navigation to the home page
            setTimeout(() => {
                navigate(path, {state: { accessToken, forceRefresh: true }});
                setIsArchiveModalVisible(false);
            }, 2000); 
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleEditClicked = (event) => {
        event.preventDefault();
        // navigate to universe edit page
        // send universe_id, access_token and existing companies
        const existingCompanies = universeData.map((item) => {
            return {
                company_id: item.company_id,
                company_name: item.company_name,
                linkedin_url: item.company_linkedin_url,
            };
        });

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Edit Universe",
                universeId: universeId,
                universeName: universeName,
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Edit Universe",
                universeId: universeId,
                universeName: universeName,
                user: toJS(userAccountDataStore.user),
            });
        }

        navigate(`/universe/edit?universe_id=${universeId}`, { state: { universeName, universeId, clientIds, accessToken, existingCompanies } });

    };

    const handleInsightsClicked = (event) => {
        event.preventDefault();
        
        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Growth",
                universeId: universeId,
                universeName: universeName,
                url: companyInsightsUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Growth",
                universeId: universeId,
                universeName: universeName,
                url: companyInsightsUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyInsightsUrl, '_blank');
    };

    const handleTeamsClicked = (event) => {
        event.preventDefault();

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Team Growth",
                universeId: universeId,
                universeName: universeName,
                url: companyTeamsUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Team Growth",
                universeId: universeId,
                universeName: universeName,
                url: companyTeamsUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyTeamsUrl, '_blank');
    };

    const handleTalentClicked = (event) => {
        event.preventDefault();

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Flow",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Flow",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyTalentUrl, '_blank');
    };

    const handleTalentRetentionClicked = (event) => {
        event.preventDefault();

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Retention",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentRetentionUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Retention",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentRetentionUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyTalentRetentionUrl, '_blank');
    };

    const handleTalentRetentionPlusClicked = (event) => {
        event.preventDefault();

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Retention Plus",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentRetentionUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Talent Retention Plus",
                universeId: universeId,
                universeName: universeName,
                url: companyTalentRetentionUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyTalentRetentionPlusUrl, '_blank');
    };

    const handleTenureClicked = (event) => {
        event.preventDefault();

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Company Tenure",
                universeId: universeId,
                universeName: universeName,
                url: companyTenureUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Company Tenure",
                universeId: universeId,
                universeName: universeName,
                url: companyTenureUrl,
                comnpanyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        window.open(companyTenureUrl, '_blank');
    };

    const handleBizDevClicked = (event) => {
        event.preventDefault();
        // navigate to biz dev page
        if (companySummaryData === null) {
            return;
        }

        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Create Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Create Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        const targetCompanyName = companySummaryData['company_name'];
        const targetCompanyId = companySummaryData['company_id'];

        let data = {
            universeName, 
            universeId, 
            targetCompanyId, 
            targetCompanyName, 
            datasetName,
            dataVersionDate: toJS(dataVersionDateStore.dataVersionDate),
            accessToken
        };
        const queryString = objectToQueryString(data);
        if (userAccountDataStore.user.email.split('@')[1] === 'kornferry.com') {
            window.open(`/report/kf/preview?${queryString}`, '_blank');
        }
        else {
            window.open(`/report/preview?${queryString}`, '_blank');
        }

    };

    const handleDigitalBMReportClicked = (event) => {
        event.preventDefault();
        setIsDigitalBMReportModalVisible(true);
    };

    const handleTalentMgmtReportClicked = (event) => {
        event.preventDefault();
        setIsTalentMgmtReportModalVisible(true);
    };

    const handleHealthCheckReportClicked = (event) => {
        event.preventDefault();

        // open the window to create the health check report
        const targetCompanyName = companySummaryData['company_name'];
        const targetCompanyId = companySummaryData['company_id'];
        let data = {
            universeData, 
            targetCompanyId, 
            targetCompanyName,
            datasetName,
            dataVersionDate: toJS(dataVersionDateStore.dataVersionDate),
            accessToken
        };
        
        sessionStorage.setItem('healthCheckReportData', JSON.stringify(data));
        
        window.open(`/report/health-check/preview`, '_blank');
    }


    const handleHistTrendClicked = () => {
        // select data for all the dates from universeHistData
        let histData = universeHistData.filter(item => item.company_name === selectedCompany);
        const formattedData = histData.map(item => {
            const date = new Date(item.the_date);
            const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
            return {
                x: parseFloat((item.ltm_retention_rate * 100).toFixed(1)),
                y: parseFloat((item.ltm_net_hc_growth * 100).toFixed(1)),
                date: monthYear,
            };
        }).sort((a, b) => a.date - b.date);
        
        const chart = Highcharts.chart('universe-chart-container', {
            chart: {
                type: 'scatter',
                height: 720,
                animation: true,
                zoomType: 'xy',
            },
            title: {
                text: universeName,
            },
            subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[0]],
                },
                labels: {
                    formatter: function() {
                        if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                            return this.value + '%';
                        }
                        else {
                            // format number to have K for thousands and M for millions
                            return numberFormatter(this.value);
                            // return this.value;
                        }
                    }
				},
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[1]],
                },
                labels: {
					format: '{value}%'
				},
                gridLineWidth: 1,
            },
            series: [{
                name: selectedCompany + ' Historical Trend',
                data: [],
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    states: {
                        select: {
                            fillColor: 'coral',
                            radius: 10,
                            lineWidth: 0,
                        },
                    },
                    radius: 10,
                    fillOpacity: 1,
                },
            }],
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.date}',
                    },
                    marker: {
                        radius: 10,
                        fillOpacity: 1,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
        });

        let i = 0;
        const interval = setInterval(() => {
            if (i < formattedData.length) {
                // const point = formattedData[i];
                // chart.series[0].addPoint(point, true, false);
                // if (i === 0 || i === formattedData.length - 1) {
                //     chart.series[0].data[i].update({ color: 'coral' });
                // }
                // i++;
                const point = formattedData[i];
                chart.series[0].addPoint({
                    x: point.x,
                    y: point.y,
                    date: point.date,
                    marker: {
                        fillOpacity: 1
                    }
                }, true, false);

                // Update the opacity of previous points
                if (i > 0) {
                    for (let j = 0; j < i; j++) {
                        const previousPoint = chart.series[0].data[j];
                        let currentOpacity = previousPoint.marker && previousPoint.marker.fillOpacity;
                        if (currentOpacity > 0.1) {
                            currentOpacity -= 0.5;
                        }
                        previousPoint.update({
                            marker: {
                                fillOpacity: currentOpacity || 0.1
                            }
                        }, false);
                    }
                    chart.redraw();
                }

                // Color the final data point red
                if (i === 0 || i === formattedData.length - 1) {
                    chart.series[0].data[i].update({ color: 'red' });
                }

                i++;
            } else {
                clearInterval(interval);
            }
        }, 500); // Adjust the delay as needed
    };

    const handleHistTrendClickedWithTrails = () => {
        // select data for all the dates from universeHistData
        let histData = universeHistData.filter(item => item.company_name === selectedCompany);
        // select data with dates 6 months apart but keep the first and last data points
        histData = histData.filter((item, index) => index % 6 === 0 || index === 0 || index === histData.length - 1);

        const formattedData = histData.map(item => {
            const date = new Date(item.the_date);
            const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
            return {
                x: parseFloat((item.ltm_retention_rate * 100).toFixed(1)),
                y: parseFloat((item.ltm_net_hc_growth * 100).toFixed(1)),
                date: monthYear,
            };
        }).sort((a, b) => a.date - b.date);

        
        // Calculate the min and max values for the axes
        const xValues = formattedData.map(d => d.x);
        const yValues = formattedData.map(d => d.y);
        const xMin = Math.min(...xValues) - 2;
        const xMax = Math.max(...xValues) + 2;
        const yMin = Math.min(...yValues) - 2;
        const yMax = Math.max(...yValues) + 2;
        
        const chart = Highcharts.chart('universe-chart-container', {
            chart: {
                type: 'scatter',
                height: 720,
                animation: true,
                zoomType: 'xy',
                events: {
                    render: function () {
                        const series = this.series[0];
                        const renderer = this.renderer;
                        for (let i = 1; i < series.data.length; i++) {
                            const prevPoint = series.data[i - 1];
                            const currPoint = series.data[i];
                            const path = [
                                'M', prevPoint.plotX + this.plotLeft, prevPoint.plotY + this.plotTop,
                                'L', currPoint.plotX + this.plotLeft, currPoint.plotY + this.plotTop
                            ];
                            if (!currPoint.connector) {
                                currPoint.connector = renderer.path(path)
                                    .attr({
                                        'stroke-width': 1,
                                        stroke: '#7cb5ec',
                                        zIndex: 3
                                    })
                                    .add();
                            } else {
                                currPoint.connector.attr({ d: path });
                            }
                        }
                    }
                }
            },
            title: {
                text: universeName,
            },
            subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[0]],
                },
                labels: {
                    formatter: function() {
                        if (selectedChartView.id === 1 || selectedChartView.id === 2 || selectedChartView.id === 3) {
                            return this.value + '%';
                        }
                        else {
                            // format number to have K for thousands and M for millions
                            return numberFormatter(this.value);
                            // return this.value;
                        }
                    }
				},
                gridLineWidth: 1,
                min: xMin,
                max: xMax,
            },
            yAxis: {
                title: {
                    text: universeDisplayNamesForColumns[selectedChartView.value[1]],
                },
                labels: {
					format: '{value}%'
				},
                gridLineWidth: 1,
                min: yMin,
                max: yMax,
            },
            series: [{
                name: selectedCompany + ' Historical Trend',
                data: [],
                marker: {
                    enabled: true,
                    radius: 10,
                    fillOpacity: 1,
                },
            }],
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.date}',
                    },
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
        });

        let i = 0;
        const interval = setInterval(() => {
            if (i < formattedData.length) {
                const point = formattedData[i];
                chart.series[0].addPoint(point, true, false);

                if (i === 0 || i === formattedData.length - 1) {
                    chart.series[0].data[i].update({ color: 'red' });
                }

                i++;
            } else {
                clearInterval(interval);
            }
        }, 800); // Adjust the delay as needed
    };

    const objectToQueryString = (obj) => {
        const keyValuePairs = [];
        for (const key in obj) {
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    };

    const handleCompetitorChange = (event) => {
         // add or remove the competitor company from the list
        const competitorId = event.target.id;
        const competitorName = event.target.value;
        const isCheckboxChecked = event.target.checked;
        

        // const updatedCompetitorCompanies = {...competitorCompanies};

        // if (isCheckboxChecked) {
        //     updatedCompetitorCompanies[competitorId] = competitorName;
        // }
        // else {
        //     delete updatedCompetitorCompanies[competitorId];
        // }

        // setCompetitorCompanies(updatedCompetitorCompanies);


        setCompetitorCompanies(prev => {
            const updated = { ...prev };
            if (isCheckboxChecked) {
                updated[competitorId] = competitorName;
                setOrderedCompetitors(prevOrder => [...prevOrder, { id: competitorId, name: competitorName }]);
            } else {
                delete updated[competitorId];
                setOrderedCompetitors(prevOrder => prevOrder.filter(item => item.id !== competitorId));
            }
            return updated;
        });
    };

    const handleCloseDigitalBMReportModal = () => {
        setCompetitorCompanies({});
        setOrderedCompetitors([]);
        setRenameTarget(false);
        setTargetName(null);
        setIsDigitalBMReportModalVisible(false);
    };

    const handleCloseTalentMgmtReportModal = () => {
        setCompetitorCompanies({});
        setOrderedCompetitors([]);
        setRenameTarget(false);
        setTargetName(null);
        setIsTalentMgmtReportModalVisible(false);
    };

    const handleConfirmDBMReportClicked = () => {
    
        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Create DBM Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Create DBM Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        const targetCompanyName = renameTarget ? targetName : companySummaryData['company_name'];
        const targetCompanyId = companySummaryData['company_id'];

        let data = {
            universeData,
            competitorCompanies,
            orderedCompetitors,
            targetCompanyId, 
            targetCompanyName, 
            datasetName,
            dataVersionDate: toJS(dataVersionDateStore.dataVersionDate),
            accessToken
        };
        sessionStorage.setItem('dbmReportData', JSON.stringify(data));

        setCompetitorCompanies({});
        setOrderedCompetitors([]);
        setRenameTarget(false);
        setTargetName(null);
        setIsDigitalBMReportModalVisible(false);
        

        //const queryString = objectToQueryString(data);
        if (userAccountDataStore.user.email.split('@')[1] === 'kornferry.com') {
            window.open(`/report/digital-bm/kf/preview`, '_blank');
        }
        else {
            window.open(`/report/digital-bm/preview`, '_blank');
        }
    };

    const handleConfirmTMReportClicked = () => {
    
        if(userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Universe Summary",
                type: "Create TM Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Universe Summary",
                type: "Create TM Report",
                universeId: universeId,
                universeName: universeName,
                companyId: companySummaryData['company_id'],
                user: toJS(userAccountDataStore.user),
            });
        }

        const targetCompanyName = renameTarget ? targetName : companySummaryData['company_name'];
        const targetCompanyId = companySummaryData['company_id'];

        let data = {
            universeData,
            competitorCompanies,
            orderedCompetitors,
            targetCompanyId, 
            targetCompanyName, 
            datasetName,
            dataVersionDate: toJS(dataVersionDateStore.dataVersionDate),
            accessToken
        };
        sessionStorage.setItem('tmReportData', JSON.stringify(data));

        setCompetitorCompanies({});
        setOrderedCompetitors([]);
        setRenameTarget(false);
        setTargetName(null);
        setIsTalentMgmtReportModalVisible(false);

        if (userAccountDataStore.user.email.split('@')[1] === 'kornferry.com') {
            window.open(`/report/talent-mgmt/kf/preview`, '_blank');
        }
        else {
            window.open(`/report/talent-mgmt/preview`, '_blank');
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(orderedCompetitors);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setOrderedCompetitors(items);
    };



    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
        if ((accessToken !== null) || (userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
            getDataVersionDate();
        }
    }, []);

    useEffect(() => {
        if ((accessToken !== null) || (userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
            setIsPageLoading(true);
            getUniverseData();
        }
    }, []);

    useEffect(() => {
        if (noAccess) {
            alert("You don't have access to this collection. Redirecting to home page...");
            navigate('/home');
            return;
        }
        else if ((accessToken !== null) || (userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
            try {
                let universeChartOptions = getUniverseChartOptions();
                const chart = Highcharts.chart('universe-chart-container', universeChartOptions);
                if (selectedCompany !== 'All' && selectedPointIndex !== null) {
                    chart.series[0].data[selectedPointIndex].select();
                }
            }
            catch (err) {
                console.log(err);
            }
        }

    }, [universeData, selectedChartView, selectedCompany, showAverage, showMedian]);

    if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
    else {
        return (
            <div>
                {
                    isModalVisible
                    ? (
                        <div className="modal">
                            <div className="modal-content" style={{textAlign: 'center'}}>
                                <span className="close" onClick={() => setIsModalVisible(false)}>&times;</span>
                                <h2>Clone Collection</h2>
                                <p>Are you sure you want to create a copy of this collection?</p>
                                <button id="confirm-changes-button" className="button" onClick={handleConfirmClicked}>Confirm</button>
                                <br/>
                                {
                                    collectionUpdating &&
                                    (
                                        <div>
                                            <br/>
                                            <LoadingWithText texts={['Sending request...', 'Cloning Collection...', 'Finished!']} interval={10000} />
                                        </div>
                                    )
                                }
                                {
                                    collectionUpdateComplete &&
                                    (
                                        <div id="update-text">
                                            <p> Collection clone successful! Redirecting to the new collection... </p>
                                        </div>
                                    )    
                                }
                            </div>
                        </div>
                    )
                    : null
                }
                {
                    isArchiveModalVisible
                    ? (
                        <div className="modal">
                            <div className="modal-content" style={{textAlign: 'center'}}>
                                <span className="close" onClick={() => setIsArchiveModalVisible(false)}>&times;</span>
                                <h2>Archive Collection</h2>
                                <p>Are you sure you want to archive this collection?</p>
                                <button id="confirm-changes-button" className="button" onClick={handleArchiveConfirmClicked}>Confirm</button>
                                <br/>
                                {
                                    collectionArchiving &&
                                    (
                                        <div>
                                            <br/>
                                            <LoadingWithText texts={['Sending request...', 'Archiving Collection...', 'Finished!']} interval={10000} />
                                        </div>
                                    )
                                }
                                {
                                    collectionArchiveComplete &&
                                    (
                                        <div id="update-text">
                                            <p> Collection archive successful! Redirecting to home... </p>
                                        </div>
                                    )    
                                }
                            </div>
                        </div>
                    )
                    : null
                }
                {
                    isDigitalBMReportModalVisible
                    ? (
                        <div className="modal">
                            <div className="modal-content" style={{textAlign: 'center'}}>
                                <span className="close" onClick={handleCloseDigitalBMReportModal}>&times;</span>
                                <h2>Create Digital BM Report</h2>
                                <div>
                                    <p> Target: <b>{companySummaryData['company_name']}</b></p>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={renameTarget}
                                            onChange={() => setRenameTarget(!renameTarget)}
                                        /> Custom target name
                                    </label>
                                    <span style={{marginLeft: '10px'}} />
                                    {renameTarget && (
                                        <input
                                            type="text"
                                            value={targetName}
                                            onChange={(e) => setTargetName(e.target.value)}
                                        />
                                    )}
                                </div>
                                <div className="dotted-line" />
                                <p>Select competitor companies to include in the report:</p>
                                {/* loop thru universeData and display company_name along with checkboxes except for selectedCompany */}
                                <div className="checkbox-group-container" >
                                    { universeData.filter((company) => company.company_name !== companySummaryData['company_name']).map((company) => (
                                        <div key={company.company_id} className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                id={company.company_id}
                                                name="company"
                                                value={company.company_name}
                                                onChange={handleCompetitorChange}
                                                // checked is true if company_id is in competitorCompanies
                                                checked={competitorCompanies.hasOwnProperty(company.company_id)}
                                            />
                                            <label htmlFor={company.company_id}>{company.company_name}</label>
                                        </div>
                                    ))}
                                </div>
                                

                                {orderedCompetitors.length > 0 && (
                                    <>
                                        <div className="dotted-line" />
                                        <p>Drag to set the order of the selected companies:</p>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="competitors">
                                                {(provided) => (
                                                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: '0'}}>
                                                        {orderedCompetitors.map((competitor, index) => (
                                                            <Draggable key={competitor.id} draggableId={competitor.id} index={index}>
                                                                {(provided) => (
                                                                    <>
                                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        {index + 1}. {competitor.name}
                                                                    </li>
                                                                    
                                                                    <span style={{padding: '2px'}} />
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </>
                                )}

                                <br/>
                                <button id="confirm-changes-button" className="button" onClick={handleConfirmDBMReportClicked}>Create Report</button>
                            </div>
                        </div>
                    )
                    : null
                }
                {
                    isTalentMgmtReportModalVisible
                    ? (
                        <div className="modal">
                            <div className="modal-content" style={{textAlign: 'center'}}>
                                <span className="close" onClick={handleCloseTalentMgmtReportModal}>&times;</span>
                                <h2>Create Talent Management Report</h2>
                                <div>
                                    <p> Target: <b>{companySummaryData['company_name']}</b></p>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={renameTarget}
                                            onChange={() => setRenameTarget(!renameTarget)}
                                        /> Custom target name
                                    </label>
                                    <span style={{marginLeft: '10px'}} />
                                    {renameTarget && (
                                        <input
                                            type="text"
                                            value={targetName}
                                            onChange={(e) => setTargetName(e.target.value)}
                                        />
                                    )}
                                </div>
                                <div className="dotted-line" />
                                <p>Select competitor companies to include in the report:</p>
                                {/* loop thru universeData and display company_name along with checkboxes except for selectedCompany */}
                                <div className="checkbox-group-container" >
                                    { universeData.filter((company) => company.company_name !== companySummaryData['company_name']).map((company) => (
                                        <div key={company.company_id} className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                id={company.company_id}
                                                name="company"
                                                value={company.company_name}
                                                onChange={handleCompetitorChange}
                                                // checked is true if company_id is in competitorCompanies
                                                checked={competitorCompanies.hasOwnProperty(company.company_id)}
                                            />
                                            <label htmlFor={company.company_id}>{company.company_name}</label>
                                        </div>
                                    ))}
                                </div>
                                

                                {orderedCompetitors.length > 0 && (
                                    <>
                                        <div className="dotted-line" />
                                        <p>Drag to set the order of the selected companies:</p>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="competitors">
                                                {(provided) => (
                                                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: '0'}}>
                                                        {orderedCompetitors.map((competitor, index) => (
                                                            <Draggable key={competitor.id} draggableId={competitor.id} index={index}>
                                                                {(provided) => (
                                                                    <>
                                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        {index + 1}. {competitor.name}
                                                                    </li>
                                                                    
                                                                    <span style={{padding: '2px'}} />
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </>
                                )}

                                <br/>
                                <button id="confirm-changes-button" className="button" onClick={handleConfirmTMReportClicked}>Create Report</button>
                            </div>
                        </div>
                    )
                    : null
                }
                <div style={{textAlign: 'center'}}>
                    {
                        isEditable && datasetName === "etl" &&
                        (
                            <div>
                            <button className="edit-button" onClick={handleEditClicked}>Edit</button>
                            </div>
                        )
                    }
                    {
                        datasetName === 'etl'
                        ? (
                            isDeletable
                            ? (
                                <>
                                <div>
                                    <button className="dup-button" onClick={handleCloneClicked}>Clone</button>
                                </div>
                                <div>
                                    <button className="archive-button" onClick={handleArchiveClicked}>Archive</button>
                                </div>
                                </>

                            )
                            : (
                                <div>
                                    <button className="dup-button" onClick={handleCloneClicked}>Clone</button>
                                </div>
                            )
                        )
                        : (
                            isDeletable &&
                            (
                                <div>
                                    <button className="dup-button" onClick={handleArchiveClicked}>Archive</button>
                                </div>
                            )
                        )
                    }
                    <h1>
                        Collection: {universeName}
                    </h1>
                </div>
                { isPageLoading ? (
                    <div style={{ textAlign: "center" }} ><ClipLoader/> </div>
                ) : (
                <div>
        
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {/* <div style={{ fontSize: '20px', marginRight: '50px'}}>Total Annual Revenue: <b>{numberToCurrencyUSDFormatter(totalRevenue)}</b></div> */}
                    <div style={{ fontSize: '20px', marginLeft: '10px', marginRight:'50px'}}>Total Companies: <b>{totalCompanies}</b></div>
                    <div style={{ fontSize: '20px', marginLeft: '10px'}}>Total Employees: <b>{numberFormatter(totalEmployees)}</b></div>
                </div>
                <div style={{textAlign: 'center'}} >
                    <p>
                        Click on any data point to view company details. 
                    </p>
                </div>
                <div className='chart-container' >
                    <div className="chart-container-left-universe">
                        <h3 style={{ textAlign: "center"}}>Filters</h3>
                        <br />
                        <div className="checkbox-group-container" >
                            { chartViewOptions.map((chartViewOption) => (
                            <div key={chartViewOptions.id} className="checkbox-container">
                                <input
                                    type="radio"
                                    id={chartViewOption.id}
                                    name="chartViewOption"
                                    // checked={selectedCheckbox === timeOption}
                                    defaultChecked={chartViewOption.label === "Employee Retention vs Net Headcount Growth"}
                                    onChange={() => handleChartViewChange(chartViewOption)}
                                />
                                <label htmlFor={chartViewOption.id}>{chartViewOption.label}</label>
                            </div>
                        ))}
                        </div>
                        <br />
                        <div style={{textAlign: 'center'}}>
                            <label>
                                Highlight:&nbsp;
                                <br/>
                                <select style={{textAlign: 'center', width: '200px', height: '30px', fontSize: '16px', padding: '5px'}} 
                                        value={selectedCompany} onChange={handleCompanyChange}>
                                    {
                                        companyOptions.map((value) => (
                                                <option key={value} value={value}>
                                                {value}
                                                </option>
                                            )
                                        )
                                    }
                                </select>
                            </label>
                        </div>
                        <br/>
                        <div>
                            <label>
                                <input type="checkbox" checked={showAverage} onChange={() => setShowAverage(!showAverage)} />
                                Show Average Overlay
                            </label>
                            <br/>
                            <label>
                                <input type="checkbox" checked={showMedian} onChange={() => setShowMedian(!showMedian)} />
                                Show Median Overlay
                            </label>
                        </div>
                        <br />
                    </div>
                    <div className="chart-container-middle-universe" id="universe-chart-container" >
                    </div>
                    <div className='chart-container-right-universe'>
                        <div className = 'chart-container-right-child-universe' id="company-summary-container">
                            { companySummaryData ? (
                                <div>
                                    <h3 style={{ textAlign: "center"}}>{companySummaryData.company_name}</h3>
                                    <p>
                                        {companySummaryData.company_industry} <br/>
                                        {companySummaryData.company_headquarters_address} <br/>
                                        {companySummaryData.company_headquarters_country}
                                    </p>
                                    <div>
                                        <a href={companySummaryData.company_website} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px'}}>Website</a> 
                                        <a href={companySummaryData.company_linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn</a> <br/> <br/>
                                    </div>
                                    { 
                                        isTelemetryLogin() || isKornFerryLogin() || isEndavaLogin() || !isRestrictedLogin()
                                        ? (
                                            <>
                                                <hr className="dotted-line" />
                                                <div style={{textAlign: 'center', fontWeight: 'bold'}}> Reports </div>
                                            </>
                                            
                                        )
                                        : null
                                    }
                                    <div style={{textAlign: 'center'}}>
                                        {
                                            isTelemetryLogin() || isKornFerryLogin() || isEndavaLogin() || !isRestrictedLogin()
                                            ? (
                                                <button className="button" onClick={handleBizDevClicked}> Biz Dev </button>
                                            )
                                            : null
                                        }
                                        
                                        { isTelemetryLogin() || isEndavaLogin() || (isKornFerryLogin() && KF_DIGITAL_REPORT_ACCESS_LIST.includes(userAccountDataStore.user.email)) || !isRestrictedLogin()
                                            ? (
                                                <button className="button" onClick={handleDigitalBMReportClicked}> Digital Benchmarking </button>
                                            )
                                            : null
                                        }
                                        {
                                            isTelemetryLogin() && (
                                                <button className="button" onClick={handleHealthCheckReportClicked}> Health Check </button>
                                            )
                                        }
                                        {
                                            isTelemetryLogin() && (
                                                <button className="button" onClick={handleTalentMgmtReportClicked}> Talent Management </button>
                                            )
                                        }
                                        
                                        {/* <button className="button" onClick={handleHistTrendClickedWithTrails}> Historical Trend </button> */}
                                    </div>
                                    
                                    <hr className="dotted-line" />
                                    <div style={{textAlign: 'center', fontWeight: 'bold'}}> Interactive Insights </div>
                                    <div style={{textAlign: 'center'}}>
                                        <button className="button" onClick={handleInsightsClicked}> Company </button>
                                        
                                        <button className="button" onClick={handleTeamsClicked}> Teams </button>
                                        
                                        <button className="button" onClick={handleTalentClicked}> Talent </button>

                                        {
                                            displayTalentRetention() && (
                                                <button className="button" onClick={handleTalentRetentionClicked}> Talent Retention </button>
                                            )
                                        }

                                        {
                                            displayTalentRetentionPlus() && (
                                                <button className="button" onClick={handleTalentRetentionPlusClicked}> Talent Retention + </button>
                                            )
                                        }
                                        
                                        <button className="button" onClick={handleTenureClicked}> Tenure </button>
                                        &nbsp;
                                    </div>
                                    <p>
                                        Age (Years): <b>{companySummaryData.company_age !== null ? companySummaryData.company_age : '-'}</b> <br/> <br/>
                                        Employees (All): <b>{companySummaryData.company_employees_count_all !== null ? putCommasInNumber(companySummaryData.company_employees_count_all) : 'N/A'}</b> <br/> <br/>
                                        Employees (Public Profiles): <b>{companySummaryData.employees_count_public !== null ? putCommasInNumber(companySummaryData.employees_count_public) : 'N/A'}</b> <br/> <br/>
                                        Pct Public Profiles: <b>{companySummaryData.pct_public_profiles !== null ? `${(companySummaryData.pct_public_profiles * 100).toFixed(0)}%` : 'N/A'}</b> <br/> <br/>
                                        Pct Female: <b>{companySummaryData.avg_female_probability !== null ? `${(companySummaryData.avg_female_probability * 100).toFixed(0)}%` : 'N/A'}</b> <br/> <br/>
                                        {/* Estimated Revenue: <b>{companySummaryData.estimated_revenue !== null ? numberToCurrencyUSDFormatter(companySummaryData.estimated_revenue) : '-'}</b> <br/> <br/> */}
                                        Net Headcount Growth (Last 12 Mo): <b>{companySummaryData.ltm_net_hc_growth !== null ? `${(companySummaryData.ltm_net_hc_growth * 100).toFixed(1)}%` : 'N/A'}</b> <br/> <br/>
                                        Employee Hiring (Last 12 Mo): <b>{companySummaryData.ltm_hiring_rate !== null ? `${(companySummaryData.ltm_hiring_rate * 100).toFixed(1)}%` : 'N/A'}</b> <br/> <br/>
                                        Employee Attrition (Last 12 Mo): <b>{companySummaryData.ltm_attrition_rate ? `${(companySummaryData.ltm_attrition_rate * 100).toFixed(1)}%` : 'N/A'}</b> <br/> <br/>
                                        Employee Retention (Last 12 Mo): <b>{companySummaryData.ltm_retention_rate !== null ? `${(companySummaryData.ltm_retention_rate * 100).toFixed(1)}%` : 'N/A'}</b> <br/> <br/>
                                        Growth Efficiency (Last 12 Mo): <b>{companySummaryData.ltm_growth_productivity !== null ? `${(companySummaryData.ltm_growth_productivity * 100).toFixed(1)}%` : 'N/A'}</b> <br/> <br/>
                                        Average Company Tenure (Years): <b>{companySummaryData.avg_company_tenure_years !== null ? companySummaryData.avg_company_tenure_years.toFixed(1) : 'N/A'}</b>  <br/> <br/>
                                        Average Career Tenure (Years): <b>{companySummaryData.avg_career_tenure_years !== null ? companySummaryData.avg_career_tenure_years.toFixed(1) : 'N/A'}</b> <br/> <br/>
                                    </p>
                                    {/* <p>
                                        Total Funding Raised: <b>{companySummaryData.total_funding_raised > 0  && companySummaryData.total_funding_raised !== null ? numberToCurrencyUSDFormatter(companySummaryData.total_funding_raised) : '-'}</b> <br/> <br/>
                                        Last Funding Date: <b>{companySummaryData.last_funding_date !== null ? companySummaryData.last_funding_date : '-'}</b> <br/> <br/>
                                        Last Funding Raised: <b>{companySummaryData.last_funding_total !== null && companySummaryData.last_funding_total > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_total) : '-'}</b> <br/> <br/> 
                                        Last Funding Valuation: <b>{companySummaryData.last_funding_valuation !== null && companySummaryData.last_funding_valuation > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_valuation) : '-'}</b> <br/> <br/>
                                        Last Funding Round: <b>{companySummaryData.last_funding_round !== null && companySummaryData.last_funding_round > 0 ? numberToCurrencyUSDFormatter(companySummaryData.last_funding_round) : '-'}</b> <br/> <br/>
                                    </p> */}
                                </div>
                                
                            ) : (
                                <div style={{ textAlign: "center"}} >
                                    <h3> Company Details </h3>
                                    <p> No company selected </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ margin:'0 auto', textAlign: 'center'}}>
                    <DownloadButton 
                        data={universeData} 
                        buttonText={ 'Download Universe Data' }
                        fileName={ `${universeName}_universe_data.csv` }
                        dataType={'universeSummary'}
                        accessToken={accessToken}
                    />
                </div>
                <div style={{ margin: '10px'}}>
                    Notes:
                    <ul>
                        <li>Data as of {getLastDateOfMonth(dataVersionDateStore.dataVersionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</li>
                        <li>All data and analysis is based on publicly available profiles</li>
                    </ul>
                </div>
                <br/>
                </div> )}
            </div>
        );
    }
}

export default UniverseChartComponent;

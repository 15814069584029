export const universeDisplayNames = {
    'Employees': 'employees_count_public',
    'Net Headcount Growth': 'ltm_net_hc_growth',
    'Employee Attrition': 'ltm_attrition_rate',
    'Employee Addition': 'ltm_hiring_rate',
    'Employee Retention': 'ltm_retention_rate',
    'Growth Efficiency': 'ltm_growth_productivity',
};

export const universeDisplayNamesForColumns = Object.entries(universeDisplayNames)
    .reduce((universeDisplayNames, [key, value]) => ({ ...universeDisplayNames, [value]: key }), {});

export const lineColors = {
    // department
    'All': 'black',

    'Advisor / Board': '#FFD54D', // Light Gold
    'Executive': '#FFC300', // Gold
    
    'Customer Success & Support': '#128012', // Darker Green [JW: Added 12/13/23]
    'Customer Support': '#128012', // Darker Green
    'Marketing': '#82FF75', // Lime Green
    'Sales': '#32CD32', // Medium LimeGreen
    
    'Delivery': '#AF71E8', // Light Violet
    'Engineering': '#AF71E8', // Light Violet [JW: Added 12/13/23]
    'Product': '#8A2BE2', // Vibrant BlueViolet
    'R&D': '#6B21B0', // Medium Dark Violet
    
    'Finance': '#3251AD', // Royal Blue
    'Human Resources': '#879FE6', // Light Blue
    'IT': '#4169E1', // Medium Blue
    'Information Technology': '#4169E1', // Medium Blue [JW: Added 12/13/23]
    'Legal': '#1E90FF', // Sky Blue
    'Administrative': '#1E90FF', // Sky Blue [JW: Added 12/13/23]
    
    'Quality Assurance': '#CC919A', // Dark Pink
    'Management': '#FF697F', // Pink
    'Operations': '#FFB6C1', // Light Pink
    
    'Consulting': '#A9A9A9', // DarkGray [JW: Added 12/13/23]
    'Intern': '#A9A9A9', // DarkGray
    'Non-Work': '#808080', // Gray
    'Other': '#D3D3D3', // LightGray
    'Student': '#C0C0C0', // Silver
    'Unsure': '#E8E8E8', // VeryLightGray

    // seniority
    // 'Advisor / Board', 'Executive', 'Unsure', 'Management' already defined as part of dept
    'Individual Contributor': '#3BCC8D', // Pastel Green
    'Leadership': '#550E96', // Dark Purple
    'Student / Intern': '#C0C0C0', // Silver

    // headcount
    'Net Headcount': '#3c78d8', // Blue
    'Growth Efficiency': '#a64d79', //Magenta
    'Hiring' : '#34a853', // Green
    'Attrition' : '#f1c232', // Orange/Yellow

    // geo
    'Global': 'black',
    'North America': '#3c78d8', // Blue
    'Europe, Middle East & Africa': '#a64d79', //Magenta
    'Asia/Pacific': '#34a853', // Green
    'Latin America': '#f1c232', // Orange/Yellow
    'N/A': '#808080', // Gray

    //Gender
    'Public Profiles': 'lightgray',
    'Total Headcount': 'lightgray',

    //Tenure
    'Company Tenure 0-1': '#3c78d8', // Blue
    'Company Tenure 1-3': '#a64d79', //Magenta
    'Company Tenure 3-5': '#34a853', // Green
    'Company Tenure 5-10': '#f1c232', // Orange/Yellow
    'Company Tenure 4-5': '#808080', // Gray
    'Company Tenure 10-20': '#3c78d8', // Blue
    'Company Tenure 20+': '#a64d79', //Magenta

    'Career Tenure 0-1': '#3c78d8', // Blue
    'Career Tenure 1-3': '#a64d79', //Magenta
    'Career Tenure 3-5': '#34a853', // Green
    'Career Tenure 5-10': '#f1c232', // Orange/Yellow
    'Career Tenure 4-5': '#808080', // Gray
    'Career Tenure 10-20': '#3c78d8', // Blue
    'Career Tenure 20+': '#a64d79', //Magenta

    'Company Tenure': '#3c78d8', // Blue
    'Career Tenure': '#a64d79', //Magenta

};

export const benchmarkLineColors = {
    'All': '#800542', // Dark Red
    
    'Sales': '#CC317E', // Dark Pink
};


export const lineStyles = {
    'Net Headcount Growth (Last 12 Months)': 'Solid',
    'Hiring rate (Last 12 Months)': 'ShortDash',
    'Attrition rate (Last 12 Months)': 'ShortDot',
    'Growth Efficiency (Last 12 Months)': 'ShortDashDotDot',
    'Pct Female, New Hires (Last 12 months)': 'ShortDash',
    'Pct Female, Attrits (Last 12 months)': 'ShortDot',
    'Pct Female, Company Avg': 'Solid',
    'Benchmark Avg Net Growth (Last 12 Months)': 'LongDashDot',
    'Benchmark Avg Hiring (Last 12 Months)': 'LongDash',
    'Benchmark Avg Attrition (Last 12 Months)': 'LongDot',
    'Benchmark Avg Growth Efficiency (Last 12 Months)': 'LongDashDotDot',
    'Company Tenure Female': 'ShortDash',
    'Company Tenure Male': 'ShortDot',
    'Career Tenure Female': 'ShortDash',
    'Career Tenure Male': 'ShortDot',
};
        
import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";

class TalentInflowAndOutflowDataStore {
    loading = false;
    data = null;
    retentionData = null;
    retentionDataByTeams = null;
    retentionDataForInflow = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchTalentFlowData(companyId, accessToken, startDate, endDate, geo1, dept, seniority, dataset) {
        try {
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_TALENT_FLOW_DATA_ENDPOINT;

                let data = {
                    "company_id": parseInt(companyId),
                    "start_date": startDate,
                    "end_date": endDate,
                    "geo1": geo1,
                    "dept": dept,
                    "seniority": seniority,
                    "dataset": dataset
                };

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                    //withCredentials: true,
                    decompress: true,
                };
                if (accessToken === null || accessToken === undefined || accessToken === '') {
                    config['withCredentials'] = true;
                }

                console.log(`TalentInflowAndOutflowDataStore: Fetching talent flow data from ${apiUrl}...`);

                let response = await axios.post(apiUrl, data, config);
                if (response.status === 200) {
                    let talentFlowData = JSON.parse(response.data.data.talent_flow);
                    console.log(`TalentInflowAndOutflowDataStore: Fetched talent flow data from ${apiUrl}, ${talentFlowData.length} records`);
                    if (this.data == null) {
                        this.data = {};
                    }

                    this.data[companyId] = talentFlowData;
                }

                if (userAccountDataStore.user !== null) {
                    // track team growth event
                    apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                        companyId,
                        eventType: 'talent',
                        eventData: {
                            "url": apiUrl,
                            "path": process.env.REACT_APP_API_TALENT_FLOW_DATA_ENDPOINT,
                            "dataset": dataset,
                            "user_agent": navigator.userAgent,
                        },
                    });
                }

        } catch (error) {
            console.log(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.error = error;
            }
        }
    }

    async fetchTalentFlowRetentionData(companyId, accessToken, geo1, dept, seniority, dataset) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_ENDPOINT;

            let data = {
                "company_id": parseInt(companyId),
                "geo1": geo1,
                "dept": dept,
                "seniority": seniority,
                "dataset": dataset
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                //withCredentials: true,
                decompress: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            console.log(`TalentInflowAndOutflowDataStore: Fetching talent flow retention data from ${apiUrl}...`);

            let response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                let talentFlowRetentionData = JSON.parse(response.data.data.talent_flow_retention);
                console.log(`TalentInflowAndOutflowDataStore: Fetched talent flow retention data from ${apiUrl}, ${talentFlowRetentionData.length} records`);
                if (this.retentionData == null) {
                    this.retentionData = {};
                }

                this.retentionData[companyId] = talentFlowRetentionData;
            }

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'talent-retention',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }

        } catch (error) {
            console.log(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.error = error;
            }
        }
    }

    async fetchTalentFlowRetentionDataByTeams(companyId, accessToken, dataset) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_BY_TEAMS_ENDPOINT;

            let data = {
                "company_id": parseInt(companyId),
                "dataset": dataset
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                //withCredentials: true,
                decompress: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            console.log(`TalentInflowAndOutflowDataStore: Fetching talent flow retention data by teams from ${apiUrl}...`);

            let response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                let talentFlowRetentionData = JSON.parse(response.data.data.talent_flow_retention);
                console.log(`TalentInflowAndOutflowDataStore: Fetched talent flow retention data by teams from ${apiUrl}, ${talentFlowRetentionData.length} records`);
                if (this.retentionDataByTeams == null) {
                    this.retentionDataByTeams = {};
                }

                this.retentionDataByTeams[companyId] = talentFlowRetentionData;
            }

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'talent-retention-by-teams',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_BY_TEAMS_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }

        } catch (error) {
            console.log(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.error = error;
            }
        }
    }

    async fetchTalentFlowRetentionDataForTopInflowCompanies(companyId, infowCompanies, accessToken, geo1, dept, seniority, dataset) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_INFLOW_ENDPOINT;

            let data = {
                "company_id": parseInt(companyId),
                "inflow_company_ids": infowCompanies,
                "geo1": geo1,
                "dept": dept,
                "seniority": seniority,
                "dataset": dataset
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                //withCredentials: true,
                decompress: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            console.log(`TalentInflowAndOutflowDataStore: Fetching talent flow retention data for inflow companies from ${apiUrl}...`);

            let response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                let talentFlowRetentionForInflowData = JSON.parse(response.data.data.talent_flow_retention_for_inflow);
                console.log(`TalentInflowAndOutflowDataStore: Fetched talent flow retention data for inflow companies from ${apiUrl}, ${talentFlowRetentionForInflowData.length} records`);
                if (this.retentionDataForInflow == null) {
                    this.retentionDataForInflow = {};
                }

                this.retentionDataForInflow[companyId] = talentFlowRetentionForInflowData;
            }

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'talent-retention-inflow',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TALENT_FLOW_RETENTION_DATA_INFLOW_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }

        } catch (error) {
            console.log(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.error = error;
            }
        }
    }
}

export const talentInflowAndOutflowDataStore = new TalentInflowAndOutflowDataStore();

import React, { useEffect, useState } from 'react';
import { getApiServrUrl } from '../../utils/utils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { apiEventsStore } from '../../stores/api_events_store';
import LoadingWithText from '../common/loading_text';
import { UniverseAddCompaniesSearchResultsTable } from './universe_add_company_search_results_table';
import { set, toJS } from 'mobx';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const UniverseEdit = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [apiData, setApiData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [collectionUpdating, setCollectionUpdating] = useState(false);
    const [collectionUpdateComplete, setCollectionUpdateComplete] = useState(false);
    const [collectionUpdateError, setCollectionUpdateError] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [selectAll, setSelectAll] = useState(true);
    const [noResults, setNoResults] = useState([]); // This is used to show the "No Results" message

    const demoText = 'Enter Linkedin URLs as follows:\nhttps://www.linkedin.com/company/apple/\nhttps://www.linkedin.com/company/microsoft/\nhttps://www.linkedin.com/company/google/\n';
            
    const [inputValue, setInputValue] = useState(demoText);
    const [collectionTitle, setCollectionTitle] = useState('');
    const [accessList, setAccessList] = useState('');
    
    const [selectedCompanies, setSelectedCompanies] = useState(new Map());
    const [newCompanies, setNewCompanies] = useState(new Map());

    const [isModalVisible, setIsModalVisible] = useState(false);

    const universeName = location && location.state && location.state.universeName;
    const universeId = searchParams && searchParams.get('universe_id') || location && location.state && location.state.universeId;
    const accessToken = searchParams && searchParams.get('access_token') || location && location.state && location.state.accessToken;
    const companies = location && location.state && location.state.existingCompanies;
    const clientIds = location && location.state && location.state.clientIds;


    useEffect(() => {

        if (universeId === null || universeId === '' || universeId === undefined) {
            // navigate to home page
            navigate('/home');
        }

        setCollectionTitle(universeName);
        let accessListStr = '';
        for (let i = 0; i < clientIds.length; i++) {
            accessListStr += clientIds[i] + ',';
        }
        setAccessList(accessListStr.slice(0, -1));

        const initialSelection = new Map(companies.map(company => [company.company_id, company]));
        setSelectedCompanies(initialSelection);


        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

    const handleInputFocus = () => {
		if (inputValue === demoText) {
			setInputValue('');
		}
	};

	const handleInputBlur = () => {
		if (inputValue.trim() === '') {
			setInputValue(demoText);
		}
	};

    const handleAccessListInputChange = (event) => {
		setAccessList(event.target.value);
	};

    const handleAccessListInputFocus = () => {
		if (accessList === 'Enter comma separated email addresses') {
			setAccessList('');
		}
	};

	const handleAccessListInputBlur = () => {
		if (accessList.trim() === '') {
			setAccessList('Enter comma separated email addresses');
		}
	};

    const handleBackClicked = () => {
        let path = `/universe/summary?universe_id=${universeId}`;
        if (accessToken !== null && accessToken !== '' && accessToken !== undefined) {
            path += `&access_token=${accessToken}`;
        }
        navigate(path, {state: { universeId, accessToken }});
    };


	const handleSearchClicked = async () => {
		if (inputValue.trim() === '' || inputValue.trim() === demoText) {
			return;
		}

        setIsLoading(true);
		setApiData([]);
		setSelectedRows({});
		setNoResults([]);

		let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_SEARCH_ENDPOINT;
       
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
			decompress: true,
			// withCredentials: true,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }
		
		// trim inputValue to get the shorthand_names
		let linkedin_urls = [];
		if (inputValue.includes('\n')) {
			// split the inputValue by newline
			linkedin_urls = inputValue.trim().split('\n');
		}
		else {
			// inputValue is a single url
			linkedin_urls.push(inputValue);
		}
		// remove any spaces from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.trim());
		// remove any `\n` chars from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.replace(/\n/g, ''));
		// remove empty strings from the linkedin_urls
		linkedin_urls = linkedin_urls.filter(url => url !== '');

		let shorthand_names = [];
		for (let i = 0; i < linkedin_urls.length; i++) {
			let url = linkedin_urls[i];
            // check if last character is a slash
            if (url[url.length - 1] === '/') {
                url = url.slice(0, -1);
            }

			// remove the protocol
			url = url.replace(/(^\w+:|^)\/\//, '');
			let parts = url.split('/');

			// the third part is the shorthand_name
			let shorthand_name = parts[2];
			shorthand_names.push(escape(shorthand_name));
		}

		let data = {
			'shorthand_names': shorthand_names
		}

        try {
            setIsLoading(true);
            const response = await axios.post(apiUrl, data, config)
            if (response.status === 200 & response.data.data.results.length !== 0) {
                const responseData = JSON.parse(response.data.data.results);
                
                setApiData(responseData);
				// select all the rows by default
				const newSelectedRows = {};
                const newCompaniesToAdd = new Map();
				responseData.forEach((item, index) => {
					newSelectedRows[index] = item;
                    let company = {
                        company_id: item.company_id,
                        company_name: item.name,
                        linkedin_url: item.linkedin_url,
                    };
                    newCompaniesToAdd.set(item.company_id, company);
				});
				setSelectedRows(newSelectedRows);
                setNewCompanies(newCompaniesToAdd);
                
				if (responseData.length !== linkedin_urls.length) {
					// search didnt return results for all the companies
					let noResults = [];
					let responseDataLinkedinUrls = [];
					for (let i = 0; i < responseData.length; i++) {
						responseDataLinkedinUrls.push(responseData[i].linkedin_url);
					}

					for (let i = 0; i < linkedin_urls.length; i++) {
						if (!responseDataLinkedinUrls.includes(linkedin_urls[i])) {
							noResults.push(linkedin_urls[i]);
						}
					}

					setNoResults(noResults);
				}
            }
			
			if (userAccountDataStore.user !== null) {
				// track search event
				apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
					companyId: null,
					eventType: 'search',
					eventData: {
						"url": apiUrl,
						"path": process.env.REACT_APP_API_SEARCH_ENDPOINT,
						"dataset": null,
						"user_agent": navigator.userAgent,
					},
				});
			}
        }
        catch (error) {
            console.log(error);
        }
		finally {
			setIsLoading(false);
		}

	};

    const handleClear = () => {
        setInputValue(demoText); // Clear the input field
		setApiData([]);        // Clear the data
		setSelectedRows({}); // Clear the selected rows
		setNoResults([]); // Clear the noResults array
		setIsLoading(false); // Clear the loading flag

	};

    const handleCheckboxChange = (index, item) => {
		setSelectedRows(prevState => {
		  const newState = { ...prevState };
	  
		  if (prevState[index]) {
			delete newState[index];
		  } else {
			newState[index] = item;
		  }
	  
		  // Check if all rows are selected after this change
		  if (Object.keys(newState).length === apiData.length) {
			setSelectAll(true);
		  } else {
			setSelectAll(false);
		  }
	  
		  return newState;
		});

        const newCompaniesToAdd = new Map(newCompanies);
        if (newCompaniesToAdd.has(item.company_id)) {
            newCompaniesToAdd.delete(item.company_id);
        } else {
            let company = {
                company_id: item.company_id,
                company_name: item.name,
                linkedin_url: item.linkedin_url,
            };
            newCompaniesToAdd.set(item.company_id, company);
        }
        setNewCompanies(newCompaniesToAdd);
	  };

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedRows({});
            setNewCompanies(new Map());
		} 
		else {
			const newSelectedRows = {};
            const newCompaniesToAdd = new Map();
			apiData.forEach((item, index) => {
				newSelectedRows[index] = item;
                let company = {
                    company_id: item.company_id,
                    company_name: item.name,
                    linkedin_url: item.linkedin_url,
                };
                newCompaniesToAdd.set(item.company_id, company);
			});
			setSelectedRows(newSelectedRows);
            setNewCompanies(newCompaniesToAdd);
		}
		
		setSelectAll(!selectAll);
	};

    const toggleCompanySelection = (company) => {
        const newSelection = new Map(selectedCompanies);
        if (newSelection.has(company.company_id)) {
            newSelection.delete(company.company_id);
        } else {
            newSelection.set(company.company_id, company);
        }
        setSelectedCompanies(newSelection);
    };

    const toggleSelectAll = (checked) => {
        if (checked) {
            const newSelection = new Map(companies.map(company => [company.company_id, company]));
            setSelectedCompanies(newSelection);
        } else {
            setSelectedCompanies(new Map());
        }
    };

    const openConfirmModal = () => {
        // check if the accessList is valid
        // needs to be a comma separated list of email addresses
        
        let newClientIds = accessList.split(',');
        // remove any empty strings from the newClientIds
        newClientIds = newClientIds.filter(email => email.trim() !== '');
        // check if all the email addresses are valid
        let validEmails = true;
        for (let i = 0; i < newClientIds.length; i++) {
            if (!newClientIds[i].includes('@') || !newClientIds[i].includes('.')) {
                validEmails = false;
                break;
            }
        }

        if (!validEmails) {
            alert('Invalid email address in the access list.');
            return;
        }

        if (selectedCompanies.size === 0) {
            alert('A collection cannot be empty. Please select at least one company to save to the collection.');
            return;
        }

        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setCollectionUpdateComplete(false);
        setCollectionUpdateError(false);
    };

    const handleConfirmClicked = async () => {
        // Send the updated collection to the server
        // Update the collection with the new companies
        // Update the collection with the selected companies
        // Navigate back to the universe summary page

        if(selectedCompanies.size === 0 && newCompanies.size === 0) {
            alert('A collection cannot be empty. Please select at least one company to save to the collection.');
            return;
        }

        // check if any changes were made to the collection
        if (collectionTitle === universeName && accessList === clientIds.join(',') && selectedCompanies.size === companies.length && newCompanies.size === 0) {
            alert('No changes detected. Please make some changes to edit/update the collection.');
            setIsModalVisible(false);
            return;
        }

        setCollectionUpdating(true);

        // disable the save changes button
        // and gray it out
        document.getElementById('confirm-changes-button').disabled = true;
        document.getElementById('confirm-changes-button').style.backgroundColor = 'gray';

        // add user email and admin@telemetryllc.com at the begning of the accessList string
        let newAccessList = [`${toJS(userAccountDataStore.user.email)}`, 'admin@telemetryllc.com'];
        let accessListEmails = [];
        if (accessList.includes(',')) {
            accessListEmails = accessList.split(',');
        }
        else if (accessList.includes('\n')) {
            accessListEmails = accessList.split('\n');
        }
        else {
            accessListEmails.push(accessList);
        }
        
        // check if there are any empty strings in the accessListEmails
        accessListEmails = accessListEmails.filter(email => email.trim() !== '');

        // add the accessListEmails to the newAccessList
        newAccessList = newAccessList.concat(accessListEmails);

        // ensure all emails in newAccessList are lower case
        newAccessList = newAccessList.map(email => email.toLowerCase());
        
        
        let path = `/universe/summary?universe_id=${universeId}`;
        if (accessToken !== null && accessToken !== '' && accessToken !== undefined) {
            path += `&access_token=${accessToken}`;
        }

        let companyInfo = [];
        let newCompanyInfo = [];
        for (let company of selectedCompanies.values()) {
            companyInfo.push({
                'company_id': company.company_id,
                'linkedin_url': company.linkedin_url,
            });
        }
        for (let company of newCompanies.values()) {
            newCompanyInfo.push({
                'company_id': company.company_id,
                'linkedin_url': company.linkedin_url,
            });
        }

        let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_UPDATE_UNIVERSE_ENDPOINT;
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            decompress: true,
            // withCredentials: true,
            setTimeout: 60000,
        };
        if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }

        let data = {
            'universe_id': universeId,
            'universe_name': collectionTitle,
            'client_ids': newAccessList,
            'companies': companyInfo,
            'new_companies': newCompanyInfo,
        };

        console.log(data);

        try {
            const response = await axios.post(apiUrl, data, config);
            if (response.status !== 200) {
                console.log(response.data);
                console.log('Collection update failed');
                setCollectionUpdateError(true);
            }

            setCollectionUpdating(false);
            setCollectionUpdateComplete(true);
            
            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                // track update universe event
                mixpanelActions.track("Update Collection", {
                    universeId: universeId,
                    universeName: collectionTitle,
                    user: toJS(userAccountDataStore.user),
                    sharedWith: newAccessList,
                    companies: companyInfo,
                    newCompanies: newCompanyInfo,
                });
                amplitudeActions.track("Update Collection", {
                    universeId: universeId,
                    universeName: collectionTitle,
                    user: toJS(userAccountDataStore.user),
                    sharedWith: newAccessList,
                    companies: companyInfo,
                    newCompanies: newCompanyInfo,
                });

                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId: null,
                    eventType: 'universe_update',
                    eventData: {
                        universeId: universeId,
                        universeName: collectionTitle,
                        sharedWith: newAccessList,
                        companies: companyInfo,
                    },
                });
            }

            // defer the navigation to the summary page
            setTimeout(() => {
                navigate(path, {state: { universeId, accessToken, forceRefresh: true }});
            }, 2000);
        }
        catch (error) {
            console.log(error);
            setCollectionUpdating(false);
            setCollectionUpdateError(true);
        }
    }; 





    // const isAllSelected = companies.length && companies.every(company => selectedCompanies.has(company.company_id));
    const isAllSelected = companies.length === selectedCompanies.size;

    return (
        <div className="edit-collection">
            {
                isModalVisible
                ? (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCloseModal}>&times;</span>
                            <h2>Confirm Changes</h2>
                            <p>Are you sure you want to update the collection?</p>

                            <br/>
                            <button id="confirm-changes-button" className="button" onClick={handleConfirmClicked}>Confirm</button>
                            <br/>
                            {
                                collectionUpdating &&
                                (
                                    <div>
                                        <br/>
                                        <LoadingWithText texts={['Updating Collection...']} interval={5000} />
                                    </div>
                                )
                            }
                            {
                                collectionUpdateComplete &&
                                (
                                    <div id="update-text">
                                        <p> Collection update successful! Redirecting to summary page... </p>
                                    </div>
                                )    
                            }
                            {
                                collectionUpdateError &&
                                (
                                    <div id="update-text-error">
                                        <p> Collection update failed! Please try again in sometime. </p>
                                    </div>
                                )    
                            }
                        </div>
                    </div>
                )
                : null
            }
            <button className="back-button" onClick={handleBackClicked}>Back</button>
            <h1>Edit Collection</h1>
            {/* <p style={{marginLeft: '620px'}}> Enter comma separated email addresses </p> */}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '50%', textAlign: 'center', paddingRight: '100px'}}>
                    <h2>Rename Collection</h2>
                </div>
                
                <div style={{width: '50%', textAlign: 'center', paddingLeft: '100px'}}>
                    <h2>Access List (comma separated)</h2>
                </div>
            </div>
            <div className="input-container">
                <div className="input-group">
                    
                    <input
                        id="title-input"
                        type="text"
                        className="title-input"
                        value={collectionTitle}
                        onChange={(e) => setCollectionTitle(e.target.value)}
                        style={{borderRadius: '20px', fontSize: '16px', border: '1px solid black'}}
                    />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="input-group">
                    <textarea
                        id="access-list"
                        className="access-list"  
                        value={accessList}
                        onChange={handleAccessListInputChange}
                        style={{borderRadius: '20px', fontSize: '16px', padding: '10px', border: '1px solid black'}}
                    />
                </div>
            </div>
            <hr className='dotted-line'></hr>
            <div className="content-edit-collection">
                <div className="section-edit-collection existing-companies">
                    <h2>Remove Companies</h2>
                    {/* Content for existing companies goes here */}
                    <p> Please de-select all the companies you wish to remove from your collection </p>
                    <div className="company-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={isAllSelected}
                                            onChange={(e) => toggleSelectAll(e.target.checked)}
                                        />
                                    </th>
                                    <th>Company Name</th>
                                    <th>LinkedIn URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companies.map(company => (
                                    <tr key={company.company_id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedCompanies.has(company.company_id)}
                                                onChange={() => toggleCompanySelection(company)}
                                            />
                                        </td>
                                        <td>{company.company_name}</td>
                                        <td>
                                            <a href={company.linkedin_url} target="_blank" rel="noopener noreferrer">
                                                {company.linkedin_url}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="divider"></div> */}
                <div className="section-edit-collection add-new-companies">
                    <h2>Add New Companies</h2>
                    {/* Content for adding new companies goes here */}
                    <p> Search for new companies to add to the Collection </p>
                    <div style={{alignItems: 'center'}}>
                        <textarea
                            id='linkedin-urls'
                            value={inputValue} 
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            style={{ width: '75%', height: '100px', fontSize: '16px', padding: '10px', borderRadius: '20px' }} 
                        />
                        <br/>
                        <button 
                            className='search-button'
                            onClick={handleSearchClicked}
                        >
                            Search
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button 
                            className='clear-button'
                            onClick={handleClear}
                        >
                            Clear
                        </button>
				    </div>
                    <br/>
                    {
                        apiData.length === 0
                        ?  
                            ( 
                                isLoading
                            
                                ? (
                                    <div>
                                    <LoadingWithText texts={[]} interval={2000} />
                                    </div>
                                )
                                : 
                                // display the no results message using noResults array
                                (
                                    <div>
                                        {
                                            noResults.length !== 0
                                            ? 
                                                (
                                                    <div>
                                                        <p>No results for the following companies:</p>
                                                        <ul>
                                                            {noResults.map((item, index) => (
                                                                <li key={index}>{item}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            : null
                                        }
                                    </div>
                                )
                            )
                        : 
                            (
                                <div>
                                    <p>Please select all the companies that you wish to add to your collection</p>
                                    <UniverseAddCompaniesSearchResultsTable
                                        searchDataItems={apiData} 
                                        selectedRows={selectedRows} 
                                        handleSelect={handleCheckboxChange} 
                                        handleSelectAll={handleSelectAllChange} 
                                        selectAll={selectAll} 
                                        accessToken={accessToken}
                                        onlySearch={false}
                                    />
                                </div>
                            )
                    }
                    {/* <button className="add-to-collection-button">Add to Collection</button> */}
                </div>
            </div>
            <button className="save-changes-button" onClick={openConfirmModal}>Save Changes</button>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default UniverseEdit;

import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class TalentMgmtReportDataStore {
    loading = false;
    percentageTalentMgmtData = null;
    industryDistributionData = null;
    headcountHistoryData = null;
    headcountHistoryByTalentTypeData = null;
    headcountHistoryByFxData = null;
    retentionHistoryData = null;
    talentFlowData = null;
    tenureHistoryData = null;
    tenureHistoryByTalentTypeData = null;
    cSuiteHiresData = null;
    cSuiteExitsData = null;
    topTitlesData = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchPercentageTalentMgmtData(targetCompanyId, companyList, endDate, dataset) {
        if (this.percentageTalentMgmtData !== null && this.percentageTalentMgmtData[targetCompanyId] !== undefined) {
            return;
        }

        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let percentageTalentMgmtUrl = apiUrl + process.env.REACT_APP_API_TM_PERCENTAGE_TALENT_MGMT_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching percentage talent mgmt data from ${percentageTalentMgmtUrl}...`);

            const response = await axios.post(percentageTalentMgmtUrl, data, config);
            if (response.status === 200) {
                let percentageTalentMgmtData = JSON.parse(response.data.data.percentage_talent_mgmt);
                console.log(`TalentMgmtReportDataStore: Fetched percentage talent mgmt data from ${percentageTalentMgmtUrl}, ${percentageTalentMgmtData.length} records`);
                if (this.percentageTalentMgmtData === null) {
                    this.percentageTalentMgmtData = {};
                }
                this.percentageTalentMgmtData[targetCompanyId] = percentageTalentMgmtData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_percentage_digital',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_PERCENTAGE_TALENT_MGMT_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.industryDistributionData !== null && this.industryDistributionData[targetCompanyId] !== undefined) {
            return;
        }
        
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let industryDistributionUrl = apiUrl + process.env.REACT_APP_API_TM_INDUSTRY_DISTRIBUTION_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching industry distribution data from ${industryDistributionUrl}...`);

            const response = await axios.post(industryDistributionUrl, data, config);
            if (response.status === 200) {
                let industryDistributionData = JSON.parse(response.data.data.industry_distribution);
                console.log(`TalentMgmtReportDataStore: Fetched industry distribution data from ${industryDistributionUrl}, ${industryDistributionData.length} records`);
                if (this.industryDistributionData === null) {
                    this.industryDistributionData = {};
                }
                this.industryDistributionData[targetCompanyId] = industryDistributionData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_industry_distribution',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_INDUSTRY_DISTRIBUTION_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
            
    };

    async fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryData !== null && this.headcountHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryUrl = apiUrl + process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching headcount history data from ${headcountHistoryUrl}...`);

            const response = await axios.post(headcountHistoryUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryData = JSON.parse(response.data.data.headcount_history);
                console.log(`TalentMgmtReportDataStore: Fetched headcount history data from ${headcountHistoryUrl}, ${headcountHistoryData.length} records`);
                if (this.headcountHistoryData === null) {
                    this.headcountHistoryData = {};
                }
                
                this.headcountHistoryData[targetCompanyId] = headcountHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_headcount_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchHeadcountHistoryByTalentTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByTalentTypeData !== null && this.headcountHistoryByTalentTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByTalentTypeUrl = apiUrl + process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_BY_TALENT_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching headcount history by digital type data from ${headcountHistoryByTalentTypeUrl}...`);

            const response = await axios.post(headcountHistoryByTalentTypeUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByTalentTypeData = JSON.parse(response.data.data.headcount_history);
                console.log(`TalentMgmtReportDataStore: Fetched headcount history by digital type data from ${headcountHistoryByTalentTypeUrl}, ${headcountHistoryByTalentTypeData.length} records`);
                if (this.headcountHistoryByTalentTypeData === null) {
                    this.headcountHistoryByTalentTypeData = {};
                }
                
                this.headcountHistoryByTalentTypeData[targetCompanyId] = headcountHistoryByTalentTypeData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_headcount_history_by_talent_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_BY_TALENT_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    // async fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
    //     if (this.headcountHistoryByFxData !== null && this.headcountHistoryByFxData[targetCompanyId] !== undefined) {
    //         return;
    //     }
    //     this.loading = true;
    //     try {
    //         let apiUrl = getApiServrUrl();
    //         let headcountHistoryByFxUrl = apiUrl + process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_BY_FX_ENDPOINT;

    //         let data = {
    //             "company_list": companyList,
    //             "end_date": endDate,
    //             "dataset": dataset,
    //         };

    //         let config = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             decompress: true,
    //             withCredentials: true,
    //         };

    //         console.log(`TalentMgmtReportDataStore: Fetching headcount history by fx data from ${headcountHistoryByFxUrl}...`);

    //         const response = await axios.post(headcountHistoryByFxUrl, data, config);
    //         if (response.status === 200) {
    //             let headcountHistoryByFxData = JSON.parse(response.data.data.headcount_history_by_fx);
    //             console.log(`TalentMgmtReportDataStore: Fetched headcount history by fx data from ${headcountHistoryByFxUrl}, ${headcountHistoryByFxData.length} records`);
    //             if (this.headcountHistoryByFxData === null) {
    //                 this.headcountHistoryByFxData = {};
    //             }
                
    //             this.headcountHistoryByFxData[targetCompanyId] = headcountHistoryByFxData;
    //         }

    //         this.loading = false;
    //         if (userAccountDataStore.user !== null) {
    //             // track team growth event
    //             apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
    //                 targetCompanyId,
    //                 eventType: 'tm_headcount_history_by_fx',
    //                 eventData: {
    //                     "url": apiUrl,
    //                     "path": process.env.REACT_APP_API_TM_HEADCOUNT_HISTORY_ENDPOINT,
    //                     "dataset": dataset,
    //                     "end_date": endDate,
    //                     "target_company_id": targetCompanyId,
    //                     "company_list": companyList,
    //                     "user_agent": navigator.userAgent,
    //                 },
    //             });
    //         }
    //     }
    //     catch (error) {
    //         console.log(error);
    //         this.error = error;
    //         this.loading = false;
    //     }

    // };

    async fetchRetentionHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset, talentType) {
        if (talentType === null && this.retentionHistoryData !== null && this.retentionHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let retentionHistoryUrl = apiUrl + process.env.REACT_APP_API_TM_RETENTION_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
                "filter": talentType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching retention history data from ${retentionHistoryUrl}...`);

            const response = await axios.post(retentionHistoryUrl, data, config);
            if (response.status === 200) {
                let retentionHistoryData = JSON.parse(response.data.data.retention_history);
                console.log(`TalentMgmtReportDataStore: Fetched retention history data from ${retentionHistoryUrl}, ${retentionHistoryData.length} records`);
                if (this.retentionHistoryData === null) {
                    this.retentionHistoryData = {};
                }
                
                this.retentionHistoryData[targetCompanyId] = retentionHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_retention_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_RETENTION_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "filter": talentType,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTalentFlowData(targetCompanyId, duration, dataset, talentType) {
        if (talentType === null && this.talentFlowData !== null && this.talentFlowData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let talentFlowUrl = apiUrl + process.env.REACT_APP_API_TM_TALENT_FLOW_HISTORY_ENDPOINT;

            let data = {
                "company_id": targetCompanyId,
                "duration": duration,
                "dataset": dataset,
                "filter": talentType,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching talent flow history data from ${talentFlowUrl}...`);

            const response = await axios.post(talentFlowUrl, data, config);
            if (response.status === 200) {
                let talentflowHistoryData = JSON.parse(response.data.data.talent_flow);
                console.log(`TalentMgmtReportDataStore: Fetched talent flow history data from ${talentFlowUrl}, ${talentflowHistoryData.length} records`);
                if (this.talentFlowData === null) {
                    this.talentFlowData = {};
                }
                
                this.talentFlowData[targetCompanyId] = talentflowHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_talent_flow_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_TALENT_FLOW_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "duration": duration,
                        "target_company_id": targetCompanyId,
                        "filter": talentType,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }


    };

    async fetchTenureHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryData !== null && this.tenureHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryUrl = apiUrl + process.env.REACT_APP_API_TM_TENURE_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching tenure history data from ${tenureHistoryUrl}...`);

            const response = await axios.post(tenureHistoryUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryData = JSON.parse(response.data.data.tenure_history);
                console.log(`TalentMgmtReportDataStore: Fetched tenure history data from ${tenureHistoryUrl}, ${tenureHistoryData.length} records`);
                if (this.tenureHistoryData === null) {
                    this.tenureHistoryData = {};
                }
                
                this.tenureHistoryData[targetCompanyId] = tenureHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_tennure_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_TENURE_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchTenureHistoryByTalentTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.tenureHistoryByTalentTypeData !== null && this.tenureHistoryByTalentTypeData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let tenureHistoryByTalentTypeUrl = apiUrl + process.env.REACT_APP_API_TM_TENURE_HISTORY_BY_TALENT_TYPE_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching tenure history by digital type data from ${tenureHistoryByTalentTypeUrl}...`);

            const response = await axios.post(tenureHistoryByTalentTypeUrl, data, config);
            if (response.status === 200) {
                let tenureHistoryByTalentTypeData = JSON.parse(response.data.data.tenure_history);
                console.log(`TalentMgmtReportDataStore: Fetched tenure history by digital type data from ${tenureHistoryByTalentTypeUrl}, ${tenureHistoryByTalentTypeData.length} records`);
                if (this.tenureHistoryByTalentTypeData === null) {
                    this.tenureHistoryByTalentTypeData = {};
                }
                
                this.tenureHistoryByTalentTypeData[targetCompanyId] = tenureHistoryByTalentTypeData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_tennure_history_by_digital_type',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_TENURE_HISTORY_BY_TALENT_TYPE_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

    async fetchCSuiteHiresForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteHiresData !== null && this.cSuiteHiresData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteHiresUrl = apiUrl + process.env.REACT_APP_API_TM_CSUITE_HIRES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching csuite hires data from ${cSuiteHiresUrl}...`);

            const response = await axios.post(cSuiteHiresUrl, data, config);
            if (response.status === 200) {
                let cSuiteHiresData = JSON.parse(response.data.data.c_suite_hires);
                console.log(`TalentMgmtReportDataStore: Fetched csuite hires data from ${cSuiteHiresUrl}, ${cSuiteHiresData.length} records`);
                if (this.cSuiteHiresData === null) {
                    this.cSuiteHiresData = {};
                }
                
                this.cSuiteHiresData[targetCompanyId] = cSuiteHiresData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_csuite_hires_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_CSUITES_HIRES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchCSuiteExitsForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.cSuiteExitsData !== null && this.cSuiteExitsData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let cSuiteExitsUrl = apiUrl + process.env.REACT_APP_API_TM_CSUITE_EXITS_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching csuite exits data from ${cSuiteExitsUrl}...`);

            const response = await axios.post(cSuiteExitsUrl, data, config);
            if (response.status === 200) {
                let cSuiteExitsData = JSON.parse(response.data.data.c_suite_exits);
                console.log(`TalentMgmtReportDataStore: Fetched csuite exits data from ${cSuiteExitsUrl}, ${cSuiteExitsData.length} records`);
                if (this.cSuiteExitsData === null) {
                    this.cSuiteExitsData = {};
                }
                
                this.cSuiteExitsData[targetCompanyId] = cSuiteExitsData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_csuite_exits_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_CSUITES_EXITS_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };

    async fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.topTitlesData !== null && this.topTitlesData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let topTitlesUrl = apiUrl + process.env.REACT_APP_API_TM_TOP_TITLES_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`TalentMgmtReportDataStore: Fetching top titles data from ${topTitlesUrl}...`);

            const response = await axios.post(topTitlesUrl, data, config);
            if (response.status === 200) {
                let topTitlesData = JSON.parse(response.data.data.top_titles);
                console.log(`TalentMgmtReportDataStore: Fetched top titles data from ${topTitlesUrl}, ${topTitlesData.length} records`);
                if (this.topTitlesData === null) {
                    this.topTitlesData = {};
                }
                
                this.topTitlesData[targetCompanyId] = topTitlesData;
            }

            this.loading = false;

            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'tm_top_titles_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_TM_TOP_TITLES_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
    };
}

export const talentMgmtReportDataStore = new TalentMgmtReportDataStore();

import React, { useEffect, useState, useMemo } from "react";
import { toJS } from 'mobx';
import LoadingWithText from '../../common/loading_text';
import { talentTypeMappingReverse } from "../utils";
import { talentMgmtReportDataStore } from "../../../stores/talent_mgmt_report_data_store";

const Appendix = ({ requestData }) => {
    const { targetCompanyId, companyList, endDate, dataset } = requestData;
    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const talentTypeOrder = [
        "flag_hr_change_mgmt",
        "flag_hr_culture",
        "dei_core",
        "flag_coach",
        "flag_hr_comp_benefits",
        "flag_hr_talent_acquis",
        "flag_hr_learning_and_dev",
        "flag_hr_talent_mgmt",
        "flag_hr_ehs",
        "flag_hr_payroll",
        "flag_hr_general",
    ];

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await talentMgmtReportDataStore.fetchTopTitlesForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const topTitlesData = talentMgmtReportDataStore.topTitlesData[targetCompanyId];

            if (topTitlesData !== null) {
                setData(toJS(topTitlesData));
                console.log('topTitlesData', toJS(topTitlesData));
                setDataLoading(false);
            } else {
                console.log('topTitlesData is null');
                setDataLoading(false);
            }
        };

        getData();
    }, [targetCompanyId, companyList, endDate, dataset]);

    const getTop10Titles = (titles) => {
        const sortedEntries = Object.entries(titles)
            .sort((a, b) => b[1] - a[1]);
        
        const padded = [...sortedEntries, ...Array(10).fill(['-', 0])];
        
        return padded.slice(0, 10);
    };

    const TypesTable = ({ types, officialName, groupedData }) => {
        const categoryWidth = '16.5%'; // 100% / 6 columns
        
        // Add 'N/A' types only if there are fewer than 6 types
        const extendedTypes = [...types];
        if (extendedTypes.length < 6) {
            extendedTypes.push('N/A');
        }

        // Split into two tables of 6 columns each
        const firstTable = extendedTypes.slice(0, 6);
        const secondTable = extendedTypes.slice(6, 11);

        const renderTable = (tableTypes) => {
            const countColumnWidth = '60px';  // Adjust this value as needed
        
            return (
                <table style={{
                    verticalAlign: 'top',
                    fontSize: '12px',
                    margin: '2px',
                    width: '100%',
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                    tableLayout: 'fixed'
                }} className="company-table">
                    <colgroup>
                        {tableTypes.map((_, index) => (
                            <React.Fragment key={index}>
                                <col style={{width: `calc(20% - ${countColumnWidth})`}} />
                                <col style={{width: countColumnWidth}} />
                            </React.Fragment>
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            {tableTypes.map(type => (
                                <th key={type} colSpan="2" style={{
                                    padding: '8px',
                                    borderBottom: '1px solid #ddd',
                                    borderRight: '1px solid #ddd',
                                    backgroundColor: '#f2f2f2',
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}>
                                    {type === 'N/A' ? 'N/A' : talentTypeMappingReverse[type]}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {tableTypes.map(type => (
                                <React.Fragment key={type}>
                                    <th style={{
                                        padding: '8px',
                                        borderBottom: '1px solid #ddd',
                                        borderRight: '1px solid #ddd',
                                        backgroundColor: '#f2f2f2',
                                        fontWeight: 'bold'
                                    }}>Title</th>
                                    <th style={{
                                        padding: '8px',
                                        borderBottom: '1px solid #ddd',
                                        borderRight: '1px solid #ddd',
                                        backgroundColor: '#f2f2f2',
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }}>Count</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {tableTypes.map(type => {
                                    let titles = type === 'N/A' 
                                        ? [['-', '-']] 
                                        : getTop10Titles(groupedData[officialName][type]);
                                    return (
                                        <React.Fragment key={type}>
                                            <td style={{
                                                padding: '8px',
                                                borderBottom: '1px solid #ddd',
                                                borderRight: '1px solid #ddd',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                minHeight: '40px',
                                                verticalAlign: 'top'
                                            }}>
                                                {titles[rowIndex] ? titles[rowIndex][0] : '-'}
                                            </td>
                                            <td style={{
                                                padding: '8px',
                                                borderBottom: '1px solid #ddd',
                                                borderRight: '1px solid #ddd',
                                                textAlign: 'right',
                                                verticalAlign: 'top',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {titles[rowIndex] && titles[rowIndex][1] !== '-' ? titles[rowIndex][1] : '-'}
                                            </td>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        };

        return (
            <div>
                {renderTable(firstTable)}
                <div style={{ marginTop: '20px' }}></div>
                {renderTable(secondTable)}
            </div>
        );
    };


    const tableContent = useMemo(() => {
        if (data === null) {
            return null;
        }

        const groupedData = data.reduce((acc, item) => {
            const { official_name, talent_management_type, title_clean, num_people } = item;
            
            if (!acc[official_name]) {
                acc[official_name] = Object.fromEntries(talentTypeOrder.map(type => [type, {}]));
            }
            
            if (!acc[official_name][talent_management_type][title_clean]) {
                acc[official_name][talent_management_type][title_clean] = 0;
            }
            
            acc[official_name][talent_management_type][title_clean] += num_people;
            
            return acc;
        }, {});

        return (
            <div>
                {Object.entries(groupedData).map(([officialName]) => (
                    <div key={officialName} style={{
                        marginTop: '5px',
                        marginBottom: '20px',
                        paddingTop: '0px',
                        paddingBottom: '20px',
                    }} id={`appendix-${officialName}`}>
                        <h2>Top Titles by Talent Strategy for {officialName}</h2>
                        <TypesTable 
                            types={talentTypeOrder.slice(0, 6)} 
                            officialName={officialName} 
                            groupedData={groupedData} 
                        />
                        <TypesTable 
                            types={talentTypeOrder.slice(6)} 
                            officialName={officialName} 
                            groupedData={groupedData} 
                        />
                        {/* <TypesTable 
                            types={talentTypeOrder.slice(10)} 
                            officialName={officialName} 
                            groupedData={groupedData} 
                        /> */}
                    </div>
                ))}
            </div>
        );
    }, [data]);

    return (
        <div>
            <div style={{textAlign: 'left', marginLeft: '5%'}}>
                <h2>Appendix</h2>
            </div>
            {dataLoading ? (
                <div>
                    <LoadingWithText texts={['Requesting top titles...', 'Finished']} interval={5000} />
                </div>
            ) : (
                <div id="appendix">
                    <div style={{marginLeft: '5%'}} className="chart-container-left-dbm">
                        {tableContent}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Appendix;
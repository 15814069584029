import React from 'react';
import ErrorBoundary from './error_boundary';


const ErrorSafeComponent = ({ children, fallback }) => {
  const [hasError, setHasError] = React.useState(false);

  if (hasError) {
    return fallback || <div>Oops, something went wrong.</div>;
  }

  return (
    <ErrorBoundary onError={() => setHasError(true)}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorSafeComponent;

import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { digitalBenchmarkReportDataStore } from "../../../stores/digital_benchmark_report_data_store";
import LoadingWithText from "../../common/loading_text";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";
import { DIGITAL_TRANSFORMATION_TYPES_ORDER } from "../utils";


const DigitalHiringTrendChart = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [isPercentage, setIsPercentage] = useState(true);
    const digitalTransformationTypesOrder = DIGITAL_TRANSFORMATION_TYPES_ORDER;

    const toggleStacking = () => {
        setIsPercentage(!isPercentage);
    };

    const groupAndSumData = (data) => {
        return data.reduce((acc, curr) => {
          const year = curr.the_year_shifted;
          const type = curr.digital_transformation_type_name;
          const headcount = curr.company_headcount_new;
      
          if (!acc[year]) {
            acc[year] = {};
          }
          
          if (!acc[year][type]) {
            acc[year][type] = 0;
          }
          
          acc[year][type] += headcount;
          
          return acc;
        }, {});
    };

    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await digitalBenchmarkReportDataStore.fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const headcountHistoryByFxData = digitalBenchmarkReportDataStore.headcountHistoryByFxData[targetCompanyId];

            if (headcountHistoryByFxData !== null) {
                setData(toJS(headcountHistoryByFxData));
                console.log('headcountHistorybyFxData', toJS(headcountHistoryByFxData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryByFxData is null');
                setDataLoading(false);
            }
        };

        getData();

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawChart = (data) => {
            const groupedData = groupAndSumData(data);
            const years = Object.keys(groupedData).sort();
            const transformationTypes = [...new Set(years.flatMap(year => Object.keys(groupedData[year])))];
            // sort transformation types by order
            transformationTypes.sort((a, b) => digitalTransformationTypesOrder.indexOf(a) - digitalTransformationTypesOrder.indexOf(b));

            const seriesData = transformationTypes.map(type => ({
                name: type,
                data: years.map(year => groupedData[year][type] || 0)
            }));

            const totals = years.map(year => transformationTypes.reduce((acc, type) => acc + (groupedData[year][type] || 0), 0));

            // Reusable function to create a text element
            function createTotalTextElement(chart, total, plotX, plotTop) {
                return chart.renderer.text(
                    putCommasInNumber(total).toString(),
                    plotX + chart.plotLeft,
                    plotTop - 5
                )
                .attr({
                    align: 'center',
                    zIndex: 5
                })
                .css({
                    fontSize: '16px',
                    fontWeight: 'bold',
                })
                .add();
            }

            const chartOptions = {
                chart: {
                    type: 'column',
                    zoomType: 'xy',
                    // type: 'column',
                    height: '33%',
                    marginTop: 20,
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                    events: {
                        load: function() {
                            const chart = this;

                            // Store references to the text elements for later removal or update
                            chart.customTextElements = [];

                            chart.series[0].data.forEach((point, i) => {
                                const textElement = createTotalTextElement(chart, totals[i], point.plotX, chart.plotTop);
                                // Store the text element reference in the customTextElements array
                                chart.customTextElements.push(textElement);
                            });
                        },
                        redraw: function() {
                            const chart = this;
                            
                            // Remove the previous text elements
                            if (chart.customTextElements) {
                                chart.customTextElements.forEach(textElement => textElement.destroy());
                                chart.customTextElements = [];
                            }

                            // Redraw totals in the correct positions
                            chart.series[0].data.forEach((point, i) => {
                                const textElement = createTotalTextElement(chart, totals[i], point.plotX, chart.plotTop);
                                // Store the new text element reference
                                chart.customTextElements.push(textElement);
                            });
                        }
                    }
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },
                xAxis: {
                    categories: years,
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: isPercentage ? 'Pct Hires by Digital Function' : 'Headcount Hired by Digital Function',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        formatter: function() {
                            if (isPercentage) {
                                return this.value + '%';
                            }
                            else {
                                // add k or m if the number is greater than 1000
                                if (this.value >= 1000) {
                                    return this.value / 1000 + 'k';
                                }
                                else if (this.value >= 1000000) {
                                    return this.value / 1000000 + 'm';
                                }
                                else {
                                    return this.value;
                                }
                            }
                        },
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                tooltip: {
                    pointFormatter: function() {
                        if (isPercentage) {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                        }
                        else {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.y + '</b><br/>';
                        }
                    },
                    shared: true,
                },
                series: [
                    ...seriesData,
                    
                ],
    
                credits: {
                    enabled: false
                },
    
                plotOptions: {
                    column: {
                        stacking: isPercentage ? 'percent' : 'normal',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (isPercentage) {
                                    return this.percentage.toFixed(0) + '%';
                                }
                                else {
                                    return this.y;
                                }
                            }
                            // inside: true,
                            
                        },
                        
                    },
                }

            };
            
            Highcharts.chart('digital-hiring-by-fx-chart-container', chartOptions);

        }

        if (data !== null
            && document.getElementById('digital-hiring-by-fx-chart-container') !== null
        ) {
            const filteredData = data.filter((d) => d.linkedin_company_id === targetCompanyId);
            drawChart(filteredData);
        }
    }, [data, isPercentage]);

        return (
            <div>
                <div style={{textAlign: 'left', marginLeft: '5%'}}>
                    <h2> {targetCompanyName}: Digital Hiring Trends Over Time - Change in Headcount by Digital Function </h2>
                </div>
                {
                    dataLoading ?
                    (
                        <div>
                            <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                        </div>
                    )
                    :
                    (
                    <>
                        <button className="button" onClick={toggleStacking}>
                            Toggle {isPercentage ? 'Normal' : 'Percentage'} stacking
                        </button>
                        <br/>
                        <br/>
                        <div id="digital-hiring-trend-chart">
                            <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                                <div id="digital-hiring-by-fx-chart-container" />
                            </div>
                        </div>
                        <br/>
                    </>
                    )
                }
            </div>
        );
};

export default DigitalHiringTrendChart;

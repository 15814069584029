import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";


class HealthCheckReportDataStore {
    loading = false;
    industryDistributionData = null;
    headcountHistoryData = null;
    headcountHistoryByFxData = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }


    async fetchIndustryDistribtuionForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.industryDistributionData !== null && this.industryDistributionData[targetCompanyId] !== undefined) {
            return;
        }
        
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let industryDistributionUrl = apiUrl + process.env.REACT_APP_API_DBM_INDUSTRY_DISTRIBUTION_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`HealthCheckReportDataStore: Fetching industry distribution data from ${industryDistributionUrl}...`);

            const response = await axios.post(industryDistributionUrl, data, config);
            if (response.status === 200) {
                let industryDistributionData = JSON.parse(response.data.data.industry_distribution);
                console.log(`HealthCheckReportDataStore: Fetched industry distribution data from ${industryDistributionUrl}, ${industryDistributionData.length} records`);
                if (this.industryDistributionData === null) {
                    this.industryDistributionData = {};
                }
                this.industryDistributionData[targetCompanyId] = industryDistributionData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'health_check_industry_distribution',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_INDUSTRY_DISTRIBUTION_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }
            
    };

    async fetchHeadcountHistoryForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryData !== null && this.headcountHistoryData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryUrl = apiUrl + process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`HealthCheckReportDataStore: Fetching headcount history data from ${headcountHistoryUrl}...`);

            const response = await axios.post(headcountHistoryUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryData = JSON.parse(response.data.data.headcount_history);
                console.log(`HealthCheckReportDataStore: Fetched headcount history data from ${headcountHistoryUrl}, ${headcountHistoryData.length} records`);
                if (this.headcountHistoryData === null) {
                    this.headcountHistoryData = {};
                }
                
                this.headcountHistoryData[targetCompanyId] = headcountHistoryData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_headcount_history',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };


    async fetchHeadcountHistoryByFxForAllCompanies(targetCompanyId, companyList, endDate, dataset) {
        if (this.headcountHistoryByFxData !== null && this.headcountHistoryByFxData[targetCompanyId] !== undefined) {
            return;
        }
        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            let headcountHistoryByFxUrl = apiUrl + process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_BY_FX_ENDPOINT;

            let data = {
                "company_list": companyList,
                "end_date": endDate,
                "dataset": dataset,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            console.log(`HealthCheckReportDataStore: Fetching headcount history by fx data from ${headcountHistoryByFxUrl}...`);

            const response = await axios.post(headcountHistoryByFxUrl, data, config);
            if (response.status === 200) {
                let headcountHistoryByFxData = JSON.parse(response.data.data.headcount_history_by_fx);
                console.log(`HealthCheckReportDataStore: Fetched headcount history by fx data from ${headcountHistoryByFxUrl}, ${headcountHistoryByFxData.length} records`);
                if (this.headcountHistoryByFxData === null) {
                    this.headcountHistoryByFxData = {};
                }
                
                this.headcountHistoryByFxData[targetCompanyId] = headcountHistoryByFxData;
            }

            this.loading = false;
            if (userAccountDataStore.user !== null) {
                // track team growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    targetCompanyId,
                    eventType: 'dbm_headcount_history_by_fx',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_DBM_HEADCOUNT_HISTORY_ENDPOINT,
                        "dataset": dataset,
                        "end_date": endDate,
                        "target_company_id": targetCompanyId,
                        "company_list": companyList,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch (error) {
            console.log(error);
            this.error = error;
            this.loading = false;
        }

    };

}

export const healthCheckReportDataStore = new HealthCheckReportDataStore();

import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { talentMgmtReportDataStore } from "../../../stores/talent_mgmt_report_data_store.js";
import LoadingWithText from "../../common/loading_text.js";
import Highcharts from 'highcharts';
import { putCommasInNumber } from "../../../utils/utils.js";


const TargetToPeerTalentMakeupByFunctionChart = (props) => {
    const { targetCompanyId, targetCompanyName, companyList, endDate, dataset } = props.requestData;

    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const [isPercentage, setIsPercentage] = useState(true);
    const [showBenchmark, setShowBenchmark] = useState(false);

    const talentTypesOrder = ['Change Management', 'Culture', 'DEI', 'Coaching', 'Compensation & Benefits', 'Talent Acquisition', 'Learning & Development', 'Talent Management', 'EHS', 'Payroll', 'HR Generalists'];

    const toggleStacking = () => {
        setIsPercentage(!isPercentage);
    };


    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            await talentMgmtReportDataStore.fetchHeadcountHistoryByTalentTypeForAllCompanies(targetCompanyId, companyList, endDate, dataset);
            const headcountHistoryByFxData = talentMgmtReportDataStore.headcountHistoryByTalentTypeData[targetCompanyId];

            if (headcountHistoryByFxData !== null) {
                setData(toJS(headcountHistoryByFxData));
                console.log('headcountHistorybyFxData', toJS(headcountHistoryByFxData));
                setDataLoading(false);
            }
            else {
                console.log('headcountHistoryByFxData is null');
                setDataLoading(false);
            }
        };

        getData()

    }, [targetCompanyId, companyList, endDate, dataset]);

    useEffect(() => {
        const drawChart = (data) => {
            const processData = () => {
        
                // Group the filtered data by name and talent_mamagement_type
                let groupedData = data.reduce((acc, item) => {
                    if (!acc[item.name]) {
                        acc[item.name] = {};
                        if (item.name === targetCompanyName && showBenchmark) {
                            acc['Median'] = {};
                            acc['Top Quadrant'] = {};
                        }
                    }
                    acc[item.name][item.talent_management_type_name] = item.company_headcount_total;
                    // add 2 new keys to the groupedData object to plot the median data
                    if (item.name === targetCompanyName && showBenchmark) {
                        acc['Median'][item.talent_management_type_name] = parseFloat(item.median_pct_talent_type_benchmark * 100);
                        acc['Top Quadrant'][item.talent_management_type_name] = parseFloat(item.top_quartile_pct_talent_type_benchmark * 100);
                    }
                    return acc;
                }, {});

                // ensure Median and Top Quadrant are always at the end of the categories
                const categories = Object.keys(groupedData).sort((a, b) => {
                    if (a === 'Median') {
                        return 1;
                    }
                    else if (a === 'Top Quadrant') {
                        return 1;
                    }
                    else if (b === 'Median') {
                        return -1;
                    }
                    else if (b === 'Top Quadrant') {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                });

                const talentTypes = [...new Set(data.map(item => item.talent_management_type_name))];
                talentTypes.sort((a, b) => talentTypesOrder.indexOf(a) - talentTypesOrder.indexOf(b));
                
                const series = talentTypes.map(type => ({
                    name: type,
                    data: categories.map(name => groupedData[name][type] || 0)
                }));
                
                
                const totals = categories.filter(name => (name !== 'Median' && name !== 'Top Quadrant')).map(name => 
                    Object.values(groupedData[name]).reduce((sum, value) => sum + value, 0)
                );
          
                return { categories, series, totals };
              };
          
            const { categories, series, totals } = processData();
        
            // const { categories, series, totals } = processData();

            // Reusable function to create a text element
            function createTotalTextElement(chart, total, plotX, plotTop) {
                return chart.renderer.text(
                    (putCommasInNumber(total) || 'N/A').toString(),
                    plotX + chart.plotLeft,
                    plotTop - 5
                )
                .attr({
                    align: 'center',
                    zIndex: 5
                })
                .css({
                    fontSize: '16px',
                    fontWeight: 'bold',
                })
                .add();
            }
        
            const options = {
                chart: {
                    zoomType: 'xy',
                    type: 'column',
                    height: '33%',
                    marginTop: 20,
                    resetZoomButton: {
                        position: {
                            align: 'left',
                            verticalAlign: 'top',
                            x: 10,
                            y: -10,
                        }
                    },
                    events: {
                        load: function() {
                        const chart = this;

                        // Store references to the text elements for later removal or update
                        chart.customTextElements = [];

                        chart.series[0].data.forEach((point, i) => {
                            const textElement = createTotalTextElement(chart, totals[i], point.plotX, chart.plotTop);
                            // Store the text element reference in the customTextElements array
                            chart.customTextElements.push(textElement);
                        });
                        },
                        redraw: function() {
                            const chart = this;
                            
                            // Remove the previous text elements
                            if (chart.customTextElements) {
                                chart.customTextElements.forEach(textElement => textElement.destroy());
                                chart.customTextElements = [];
                            }

                            // Redraw totals in the correct positions
                            chart.series[0].data.forEach((point, i) => {
                                const textElement = createTotalTextElement(chart, totals[i], point.plotX, chart.plotTop);
                                // Store the new text element reference
                                chart.customTextElements.push(textElement);
                            });
                        }
                    }
                },
                title: {
                    text: null,
                },
                subtitle: {
                    text: null,
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                yAxis: {
                    title: {
                        text: isPercentage ? 'Pct Makeup by Talent Strategy Function' : 'Headcount Makeup by Talent Strategy Function',
                        style: {
                            fontSize: '20px',
                        }
                    },
                    labels: {
                        formatter: function() {
                            if (isPercentage) {
                                return this.value + '%';
                            }
                            else {
                                // add k or m if the number is greater than 1000
                                if (this.value >= 1000) {
                                    return this.value / 1000 + 'k';
                                }
                                else if (this.value >= 1000000) {
                                    return this.value / 1000000 + 'm';
                                }
                                else {
                                    return this.value;
                                }
                            }
                            
                        },
                        style: {
                            fontSize: '20px',
                        }
                    },
                },
                legend: {
                    enabled: true,
                    itemStyle: {
                        fontSize: '20px',
                    },
                },
                tooltip: {
                    pointFormatter: function() {
                        if (isPercentage) {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                        }
                        else {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.y + '</b><br/>';
                        }
                    },
                    shared: true,
                },
                series: series,
                credits: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        stacking: isPercentage ? 'percent' : 'normal',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (isPercentage) {
                                    return this.percentage.toFixed(0) + '%';
                                }
                                else {
                                    return this.y;
                                }
                            }
                            // inside: true,
                            
                        },
                        
                    },
                }
                
            };

            Highcharts.chart('digital-makeup-by-fx-target-to-peer-chart-container', options);
        };

        if (data !== null
            && document.getElementById('digital-makeup-by-fx-target-to-peer-chart-container') !== null
        ) {
            const filteredData = data.filter((d) => d.current_date_flag === 1);
            drawChart(filteredData);
        }
    }, [data, isPercentage, showBenchmark]);

        return (
            <div>
                <div style={{textAlign: 'left', marginLeft: '5%'}}>
                    <h2> Target-to-Peer Set Comparison: Talent Strategy Makeup by Function as of current month </h2>
                </div>
                {
                    dataLoading ?
                    (
                        <div>
                            <LoadingWithText texts={['Requesting headcount data...', 'Finished']} interval={5000} />
                        </div>
                    )
                    :
                    (
                    <>
                        <div>
                            <button className="button" onClick={toggleStacking}>
                                Toggle {isPercentage ? 'Normal' : 'Percentage'} stacking
                            </button>
                            <span style={{marginLeft: '5%'}} />
                            <label>
                                <input type="checkbox" checked={showBenchmark} onChange={() => setShowBenchmark(!showBenchmark)} />
                                Show Benchmark
                            </label>
                        </div>
                        <br/>
                        <br/>
                        <div id="digital-makeup-by-fx-target-peer-chart">
                            <div style={{marginLeft: '5%'}} className='chart-container-left-dbm'>
                                <div id="digital-makeup-by-fx-target-to-peer-chart-container" />
                            </div>
                            <br/>
                        </div>
                    </>
                    )
                }
            </div>
        );
};

export default TargetToPeerTalentMakeupByFunctionChart;

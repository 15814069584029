import IndustryDistributionChart from './industry_distribution_chart';
import TargetToPeerDigitalMakeupByFunctionChart from './digital_makeup_by_fx_target_peer_chart';


const DigitalCapabilities = (props) => {
    const requestData = props.requestData;
    if (requestData === null) {
        console.log('DigitalCapabilities: requestData is empty');
        return null;
    }
    const { targetCompanyName } = requestData;

    return (
        <>
            <br/>
            {/* <div className="chart-container-left-talent-bizdev" id="digital-capabilities" > */}
            {/* <div style={{marginLeft: '5%', marginRight: '5%'}} className="chart-container" id="digital-capabilities" > */}
            <div id="digital-capabilities" >
                <div className="chart-container" style={{marginLeft: '5%', marginRight: '5%'}}>
                    <div id="industryDistribution" className="chart-container-left-health-check">
                        <IndustryDistributionChart requestData={requestData} />
                    </div>
                    <div id="digitalMakeupByFunctionTargetPeer" className="chart-container-right">
                        <TargetToPeerDigitalMakeupByFunctionChart requestData={requestData} />
                    </div>
                </div>
            </div>
        </>
    );
    
};

export default DigitalCapabilities;

